import { CollectionItem } from "Component";

const Item = ({
  id,
  description,
  fingerprint,
}: {
  id: string;
  description?: string | null;
  fingerprint?: string | null;
}) => (
  <CollectionItem id={id}>
    <span>{description}</span>
    <span>{fingerprint}</span>
  </CollectionItem>
);

export default Item;
