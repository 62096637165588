import { Form, useMultiroleFormAdaptor } from "Component";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import useAuth from "./useAuth";

const Login = () => {
  const {
    sendEmail,
    sendOtp,
    errors,
    expectedOtp,
    reset: resetAuth,
  } = useAuth();

  const form = useForm<{ email: string; otp?: string }>({
    defaultValues: {
      email: "",
      otp: "",
    },
  });

  const { setValue, setFocus, setError, clearErrors } = form;

  useEffect(() => {
    if (sendOtp !== undefined) {
      setFocus("otp");
    }
  }, [sendOtp, setFocus]);

  useEffect(() => {
    if (expectedOtp !== undefined) {
      setValue("otp", expectedOtp);
    }
  }, [expectedOtp, setValue]);

  useEffect(() => {
    if (errors.email === undefined) {
      clearErrors("email");
    } else {
      setError("email", { message: errors.email, type: "manual" });
    }
  }, [errors.email, setError, clearErrors]);

  useEffect(() => {
    if (errors.otp === undefined) {
      clearErrors("otp");
    } else {
      setError("otp", { message: errors.otp, type: "manual" });
    }
  }, [errors.otp, setError, clearErrors]);

  const { register, formProps } = useMultiroleFormAdaptor({
    form,
    onSubmit: async ({ email, otp }) => {
      if (sendOtp !== undefined && otp !== undefined) {
        return await sendOtp(otp);
      }
      if (sendEmail !== undefined) {
        return await sendEmail(email);
      }
    },
    onReset: () => {
      resetAuth();
    },
  });

  return (
    <Form.Form title="Login with email" {...formProps}>
      <Form.Field
        {...register("email", { required: true })}
        readOnly={!sendEmail}
        autoFocus={!!sendEmail}
        type="email"
      />
      <Form.Field
        {...register("otp", { required: !!sendOtp })}
        hidden={!sendOtp}
      />
    </Form.Form>
  );
};

export default Login;
