import { gql } from "__generated__";
import useCollection_ from "new/model/useCollection";

const query = gql(`
  query domains($organisationId: ID!, $after: String) {
    organisation(id: $organisationId) {
      id
      domains(after: $after, first: 20) {
        edges {
          node {
            id
            name
            dns {
              txt {
                active
                target
              }
              alias {
                active
                target
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`);

const useCollection = (organisationId: string) => {
  const result = useCollection_(
    {
      query,
      keyFn: (data) => ["organisation", data.organisationId, "domain"],
      resultFn: (data) => {
        const { pageInfo, edges } = data.organisation.domains;
        return {
          pageInfo,
          nodes: edges.map(({ node }) => node),
        };
      },
    },
    { organisationId }
  );
  return result;
};

export default useCollection;
