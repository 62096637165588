import type { ReactNode } from "react";

import Alert from "./Alert";
import Container from "./Container";

const NotFound = ({ message }: { message?: ReactNode }) => (
  <Container row>
    <Alert headline="404 fo sho" details={message} />
  </Container>
);

export default NotFound;
