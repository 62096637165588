import { Auth } from "aws-amplify";
import React, { useEffect } from "react";

const Logout = ({ children }: { children?: React.ReactNode }) => {
  useEffect(() => {
    Auth.signOut();
  }, []);
  return <>{children}</>;
};

export default Logout;
