import { gql } from "__generated__";
import useMutation, { VariablesOf } from "new/model/useMutation";

const query = gql(`
  mutation deleteOrganisationMember($input: DeleteOrganisationMemberInput!) {
    deleteOrganisationMember(input: $input) {
      organisationId
      email
    }
  }
`);

const useRemove = (organisationId: string) => {
  const { mutate, ...rest } = useMutation({
    query,
    resultFn: (data) => data.deleteOrganisationMember,
    keyFn: () => ["organisation", organisationId, "member"],
  });
  return {
    remove: (props: VariablesOf<typeof query>["input"]) =>
      mutate({ input: { ...props } }),
  };
};
export default useRemove;
