import type { GraphQLResult } from "@aws-amplify/api";
import type {
  ResultOf,
  TypedDocumentNode,
  VariablesOf,
} from "@graphql-typed-document-node/core";
import type { QueryKey } from "@tanstack/react-query";
import {
  useQueryClient,
  useMutation as useRQMutation,
} from "@tanstack/react-query";
import { API, graphqlOperation } from "aws-amplify";

const useMutation = <
  TQuery extends Record<string, unknown>,
  TQueryVariables extends Record<string, unknown>,
  TDN extends TypedDocumentNode<TQuery, TQueryVariables>,
  NT
>({
  query,
  keyFn,
  resultFn,
}: {
  query: TypedDocumentNode<TQuery, TQueryVariables>;
  keyFn: (data: NT) => QueryKey;
  resultFn: (data: ResultOf<TDN>) => NT;
}) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isSuccess, data, isLoading } = useRQMutation({
    mutationFn: async (props: VariablesOf<TDN>) => {
      const ar = API.graphql(graphqlOperation(query, props));
      try {
        const r = (await ar) as GraphQLResult<ResultOf<TDN>>;
        if (r.data === undefined) {
          throw new Error("bad data");
        }
        return resultFn(r.data);
      } catch (error) {
        throw (error as any).errors[0].message;
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: keyFn(data),
      });
    },
  });

  return {
    mutate: mutateAsync,
    isSuccess,
    isLoading,
    node: data,
  };
};

export { VariablesOf };
export default useMutation;
