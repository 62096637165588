import { gql } from "__generated__";
import useMutation, { VariablesOf } from "new/model/useMutation";

const query = gql(`
  mutation createOrganisation($input: CreateOrganisationInput!) {
    createOrganisation(input: $input) {
      organisation {
        id
        name
      }
    }
  }
`);

const useCreate = () => {
  const { mutate, ...rest } = useMutation({
    query,
    resultFn: (data) => data.createOrganisation.organisation,
    keyFn: () => ["organisation"],
  });
  return {
    mutate: (props: VariablesOf<typeof query>["input"]) =>
      mutate({ input: props }),
    ...rest,
  };
};

export default useCreate;
