import classNames from "classnames";
import React from "react";
import { Link as _Link, useLocation } from "react-router-dom";

const Link = ({
  to,
  offsite = false,
  className,
  ...props
}: { to: string; offsite?: boolean; children: React.ReactNode } & Pick<
  React.ComponentProps<typeof _Link>,
  "className" | "style" | "target"
>) => {
  const { search } = useLocation();
  const _to = offsite ? to : to + search;
  return (
    <_Link className={classNames("link", className)} to={_to} {...props} />
  );
};

export default Link;
