import { Collection } from "Component";
import { Link, Navigate, Route, useParams } from "Component/router";
import SshKeyPairView from "View/SshKeyPair";
import { useCollection, useItem, useCreate } from "new/model/sshKey";
import { useState } from "react";
import type { ComponentProps } from "react";
import useFlip from "../useFlip";

import Intersection from "../Intersection";
import Routes from "../Routes";

const SshKeyPairsItem = (
  props: ComponentProps<typeof SshKeyPairView.Collection.Item>
) => (
  <Link to={props.id}>
    <SshKeyPairView.Collection.Item {...props} />
  </Link>
);
const useData = () => useFlip(useCollection());

const SshKeyPairs = () => (
  <Intersection
    collection={
      <Collection useData={useData} RenderItem={SshKeyPairsItem} />
    }
    item={<EditSshKeyPair />}
    create={<CreateSshKeyPair />}
  />
);

const CreateSshKeyPair = () => {
  const { mutate: _create } = useCreate();

  const [createdId, setCreatedId] = useState<string>();

  return createdId ? (
    <Navigate to={`../${createdId}`} />
  ) : (
    <SshKeyPairView.Item.Create
      createFn={async (props) => {
        try {
          const { id } = await _create(props);
          setCreatedId(id);
          return { success: true, data: undefined };
        } catch (error) {
          return { success: false, error: error as string };
        }
      }}
    />
  );
};

const EditSshKeyPair = () => {
  const { id } = useParams() as { id: string };
  const { node: data, remove } = useItem(id);

  const [isDeleted, setIsDeleted] = useState(false);

  return isDeleted || data === undefined ? (
    <Navigate to="../" />
  ) : (
    <Routes>
      <Route
        index
        element={
          <SshKeyPairView.Item.Edit
            item={data}
            removeFn={async () => {
              try {
                await remove();
                setIsDeleted(true);
                return { success: true, data: undefined };
              } catch (error) {
                return {
                  success: false,
                  error: error as string,
                };
              }
            }}
          />
        }
      />
    </Routes>
  );
};

export default SshKeyPairs;
