import { Collection } from "Component";
import { Link, Navigate, Route, useParams } from "Component/router";
import MemberView from "View/Organisation/Member";
import { useCollection, useCreate, useItem } from "new/model/organisation/member";
import { useState } from "react";

import Intersection from "../../Intersection";
import Routes from "../../Routes";
import useFlip from "../../useFlip";

const Member = ({ organisationId }: { organisationId: string }) => {
  const { id } = useParams() as { id: string };
  return (
    <Routes>
      <Route index element={<Navigate to="edit" />} />
      <Route
        path="edit"
        element={<EditMember organisationId={organisationId} id={id} />}
      />
    </Routes>
  );
};

const CreateMember = ({ organisationId }: { organisationId: string }) => {
  const { mutate: _create } = useCreate(organisationId);

  const [createdId, setCreatedId] = useState<string>();

  return createdId ? (
    <Navigate to={`../${createdId}`} />
  ) : (
    <MemberView.Item.Create
      createFn={async ({ email, role }) => {
        try {
          const { email: _email } = await _create({ email, role });
          setCreatedId(_email);
          return { success: true, data: undefined };
        } catch (error) {
          return { success: false, error: error as string };
        }
      }}
    />
  );
};

const Members = ({ id }: { id: string }) => {
  const useData = () => useFlip(useCollection(id));
  return (
    <Intersection
      collection={
        <Collection
          RenderItem={(props) => (
            <Link to={props.id} style={{ width: "100%" }}>
              <MemberView.Collection.Item {...props} />
            </Link>
          )}
          useData={useData}
        />
      }
      item={<Member organisationId={id} />}
      create={<CreateMember organisationId={id} />}
    />
  );
};

const EditMember = ({
  organisationId,
  id,
}: {
  organisationId: string;
  id: string;
}) => {
  const [isDeleted, setIsDeleted] = useState(false);

  const { node: data, remove: _remove } = useItem(organisationId, id);

  const remove = async () => {
    try {
      await _remove();
      setIsDeleted(true);
      return {
        success: true as const,
        data: undefined,
      };
    } catch (error) {
      return {
        success: false as const,
        error: error as string,
      };
    }
  };

  return isDeleted || data === undefined ? (
    <Navigate to="../../" />
  ) : (
    <MemberView.Item.Edit item={data} removeFn={remove} />
  );
};

export default Members;
