import { ErrorBoundary, init } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Loading } from "Component";
import { AlertProvider, useAuth, useConfig } from "hooks";
import { Config as ConfigProvider } from "provider";
import Auth from "provider/Auth";
import { Suspense, useEffect } from "react";
import type { ReactNode } from "react";
// import Page from "new/Component/Page";
import "site.css";

import Router from "./Router";

const AuthedApp = () => {
  const { username } = useAuth();
  return <Router username={username || undefined} />;
};

const ErrorPage = () => (
  <div>
    <h2>Something went wrong</h2>
    <div>
      <a href="/">click here to try a reload</a>
    </div>
  </div>
);

const Sentry = ({ children }: { children: ReactNode }) => {
  const config = useConfig();
  const sentryConfig = config.sentry;
  useEffect(() => {
    init(sentryConfig);
  }, [sentryConfig]);
  return (
    <ErrorBoundary fallback={ErrorPage} showDialog>
      {children}
    </ErrorBoundary>
  );
};

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        suspense: true,
      },
    },
  });
  // return (
  //   <Page
  //     header={
  //       <>
  //         <h1>lhr</h1>
  //         <nav>
  //           <ul>
  //             <li><a href="#hh" id="hh">clickme</a></li>
  //             <li><a href="#ha" id="ha">clickme</a></li>
  //             <li><a href="#hb" className="active">class active</a></li>
  //             <li><a href="#hc" className="disabled">class disabled</a></li>
  //           </ul>
  //           <ul>
  //             <li>on the right</li>
  //           </ul>
  //         </nav>
  //       </>
  //     }
  //   >
  //     <nav>
  //       <a href="#yolo" id="yolo">new nav</a>
  //     </nav>
  //     <section>
  //       <nav>
  //         <a href="#yolo2" id="yolo2">new nav</a>
  //       </nav>
  //     </section>
  //     <section>this is a section<section>this is a section in a section</section></section>
  //     <section>this is a section</section>
  //   </Page>
  // );

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loading message="beep boop i am loading" />}>
        <ConfigProvider>
          <Sentry>
            <Suspense fallback={<Loading message="beep boop i am loading" />}>
              <Auth>
                <AlertProvider>
                  <AuthedApp />
                </AlertProvider>
              </Auth>
            </Suspense>
          </Sentry>
        </ConfigProvider>
      </Suspense>
    </QueryClientProvider>
  );
};

export default App;
