import { gql } from "__generated__";
import useMutation, { VariablesOf } from "new/model/useMutation";

const query = gql(`
  mutation updateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      organisation {
        id
        name
      }
    }
  }
`);

const useUpdate = () => {
  const { mutate, ...rest } = useMutation({
    query,
    resultFn: (data) => data.updateOrganisation.organisation,
    keyFn: () => ["organisation"],
  });
  return {
    update: (props: VariablesOf<typeof query>["input"]) =>
      mutate({ input: { ...props } }),
    ...rest,
  };
};
export default useUpdate;
