import type { GraphQLResult } from "@aws-amplify/api";
import type {
  ResultOf,
  TypedDocumentNode,
  VariablesOf,
} from "@graphql-typed-document-node/core";
import type { QueryKey } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { API, graphqlOperation } from "aws-amplify";

const useItem = <
  TQuery extends Record<string, unknown>,
  TQueryVariables extends Record<string, unknown>,
  TDN extends TypedDocumentNode<TQuery, TQueryVariables>,
  NT
>(
  {
    keyFn,
    query,
    resultFn,
  }: {
    keyFn: (data: VariablesOf<TDN>) => QueryKey;
    query: TypedDocumentNode<TQuery, TQueryVariables>;
    resultFn: (data: ResultOf<TDN>) => NT;
  },
  props: VariablesOf<TDN> = {} as VariablesOf<TDN>
) => {
  const { data, isLoading } = useQuery({
    queryKey: keyFn(props),
    queryFn: async () => {
      const ar = API.graphql(graphqlOperation(query, props));
      const r = (await ar) as GraphQLResult<ResultOf<TDN>>;
      if (r.data === undefined) {
        throw new Error("bad data");
      }
      return resultFn(r.data);
    },
  });

  return {
    node: data,
    loading: isLoading,
  };
};

export { VariablesOf };
export default useItem;
