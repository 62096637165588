import { gql } from "__generated__";
import useCollection_ from "new/model/useCollection";

const query = gql(`
  query members($organisationId: ID!, $after: String) {
    organisation(id: $organisationId) {
      id
      members(after: $after, first: 20) {
        nodes {
          email
          role
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`);

const useCollection = (organisationId: string) => {
  const { nodes, ...result } = useCollection_(
    {
      query,
      keyFn: (data) => ["organisation", data.organisationId, "member"],
      resultFn: (data) => {
        const { pageInfo, nodes } = data.organisation.members;
        return {
          pageInfo,
          nodes,
        };
      },
    },
    { organisationId }
  );

  return {
    nodes: nodes?.map((item) => ({ id: item.email, ...item })),
    ...result,
  };
};

export default useCollection;
