import { forwardRef } from "react";

import Field from "./Field";
import TextArea from "./TextArea";

const TextAreaField = forwardRef(function TextAreaField(
  {
    ...props
  }: Pick<
    React.ComponentProps<typeof TextArea>,
    "id" | "autoFocus" | "disabled" | "hidden" | "rows" | "readOnly"
  > &
    (
      | {
          description?: string;
          name: string;
        }
      | {
          description: string;
          name?: string;
        }
    ) & {
      error: string | undefined;
    },
  ref: React.ComponentPropsWithRef<typeof TextArea>["ref"]
) {
  return props.hidden ? (
    <textarea ref={ref} {...props} />
  ) : (
    <Field<typeof TextArea> ref={ref} as={TextArea} {...props} />
  );
});

export default TextAreaField;
