import { useMemo } from "react";
import type { ReactElement } from "react";

import Button from "../Button";
import Container from "../Container";
import style from "./Collection.module.css";

interface NewCollectionProps<T extends object> {
  RenderItem: (_arg0: T & { key: string }) => JSX.Element;
  useData: () => {
    items?: T[];
    loadMore?: () => void;
  };
  PreRender?: (props: { items: T[]; proposed: ReactElement }) => ReactElement;
}

const Collection = <D extends { id: string }>({
  useData,
  RenderItem,
  PreRender = ({ proposed }) => proposed,
}: NewCollectionProps<D>) => {
  const { items, loadMore } = useData();
  const list = useMemo(
    () => items === undefined ? null : (
      <PreRender
        items={items}
        proposed={
          items.length === 0 ? (
            <Container>there&apos;s nothing here yet</Container>
          ) : (
            <Container<"ul"> as="ul" className="list">
              {items.map((item) => (
                <Container<"li"> as="li" className="list-item" key={item.id}>
                  <RenderItem key={item.id} {...item} />
                </Container>
              ))}
            </Container>
          )
        }
      />
    ),
    [items, PreRender]
  );

  return (
    <Container border spaced>
      {list}
      <Button
        priority="secondary"
        hidden={!loadMore}
        disabled={!loadMore}
        onClick={loadMore}
      >
        load more
      </Button>
    </Container>
  );
};

export default Collection;
