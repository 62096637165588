import { gql } from "__generated__";
import useMutation, { VariablesOf } from "new/model/useMutation";

const query = gql(`
  query organisationBilling($id: ID!, $interval: BillingIntervalEnum = year, $returnUrl: AWSURL) {
    organisation(id: $id) {
      id
      name
      billing {
        active
        needsAttention
        checkoutUrl(interval: $interval, returnUrl: $returnUrl)
        billingPortalSessionUrl(returnUrl: $returnUrl)
      }
    }
  }
`);

const useUpdate = (id: string) => {
  const { mutate, ...rest } = useMutation({
    query,
    resultFn: (data) => data.organisation,
    keyFn: () => ["organisation", id, "billing"],
  });
  return {
    update: (props: Omit<VariablesOf<typeof query>, "id">) =>
      mutate({ id, ...props }),
    ...rest,
  };
};
export default useUpdate;
