import { gql } from "__generated__";
import useItem_ from "new/model/useItem";

import useRemove from "./useRemove";

const query = gql(`
  query organisationMember($organisationId: ID!, $email: AWSEmail!) {
    organisationMember(organisationId: $organisationId, email: $email) {
      email
      role
    }
  }
`);

const useItem = (organisationId: string, email: string) => {
  const item = useItem_(
    {
      query,
      keyFn: (data) => ["organisation", organisationId, "member", data.email],
      resultFn: (data) => data.organisationMember,
    },
    { organisationId, email }
  );
  const { remove } = useRemove(organisationId);
  return {
    ...item,
    remove: () => remove({ organisationId, email }),
  };
};

export default useItem;
