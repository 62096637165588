import { CodeBlock, Container, useMultiroleFormAdaptor } from "Component";
import { Form } from "Component";
import { Alert } from "Component";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import type { ReturnT as DataReturnT } from "../../DataCallback";

const Create = ({
  createFn,
}: {
  createFn: ({
    description,
    publicKey,
  }: {
    description: string;
    publicKey: string;
  }) => DataReturnT<unknown>;
}) => {
  const form = useForm<{
    description: string;
    submitConfirmed: true;
    _server?: string;
    publicKey: string;
    publicKeyFiles: FileList | null;
  }>({
    defaultValues: {
      description: "",
      publicKey: "",
      publicKeyFiles: null,
      submitConfirmed: true,
    },
  });
  const {
    setError,
    formState: { errors },
    watch,
    setValue,
    setFocus,
  } = form;

  const { formProps, register } = useMultiroleFormAdaptor({
    form,
    onSubmit: async ({
      submitConfirmed: _ignored1, // eslint-disable-line @typescript-eslint/no-unused-vars
      publicKeyFiles: _ignored2, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...data
    }) => {
      if (data.publicKey) {
        const result = await createFn(data);

        if (!result.success) {
          setError("root.serverError", { message: result.error });
        }
      }
    },
  });

  const sshCmd = "ssh-keygen -t ed25519";

  const publicKeyFiles = watch("publicKeyFiles");
  useEffect(() => {
    if (publicKeyFiles && publicKeyFiles.length === 1) {
      const publicKeyFile = publicKeyFiles[0];
      publicKeyFile.text().then((text) => {
        setValue("publicKey", text);
        const description = text.split(" ")[2];
        if (description) {
          setValue("description", description);
          setFocus("description");
        }
      });
    }
  }, [publicKeyFiles, setValue]);

  return (
    <Form.Form title="add your SSH public key" {...formProps}>
      <Form.Field {...register("description")} autoFocus />
      <Form.TextAreaField {...register("publicKey")} description="public key" />
      <Form.Field
        {...register("publicKeyFiles")}
        type="file"
        description="upload public key file"
        accept=".pub"
      />
      {errors.root?.serverError && errors.root?.serverError.message && (
        <Alert headline={errors.root?.serverError.message} />
      )}
      <Container>
        <span>run this command to generate a new ssh key</span>
        <CodeBlock code={sshCmd} />
      </Container>
    </Form.Form>
  );
};
export default Create;
