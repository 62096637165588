import type { GraphQLResult } from "@aws-amplify/api";
import type {
  ResultOf,
  TypedDocumentNode,
  VariablesOf,
} from "@graphql-typed-document-node/core";
import type { QueryFunctionContext, QueryKey } from "@tanstack/react-query";
import { useInfiniteQuery } from "@tanstack/react-query";
import { API, graphqlOperation } from "aws-amplify";
import { useMemo } from "react";

const useCollection = <
  TQuery extends Record<string, unknown>,
  TQueryVariables extends Record<string, unknown>,
  TDN extends TypedDocumentNode<TQuery, TQueryVariables>,
  NT
>(
  {
    keyFn,
    query,
    resultFn,
  }: {
    keyFn: (data: VariablesOf<TDN>) => QueryKey;
    query: TypedDocumentNode<TQuery, TQueryVariables>;
    resultFn: (data: ResultOf<TDN>) => {
      pageInfo: {
        hasNextPage: boolean;
        endCursor?: string | null;
      };
      nodes: NT[];
    };
  },
  props: VariablesOf<TDN> = {} as VariablesOf<TDN>
) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: keyFn(props),
    queryFn: async ({
      pageParam,
    }: QueryFunctionContext<QueryKey, VariablesOf<ResultOf<TDN>>>) => {
      const ar = API.graphql(
        graphqlOperation(query, {
          ...props,
          ...(pageParam === undefined ? {} : pageParam),
        })
      );
      const r = (await ar) as GraphQLResult<ResultOf<TDN>>;
      if (r.data === undefined) {
        throw new Error("bad data");
      }
      return resultFn(r.data);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.pageInfo.hasNextPage
        ? { after: lastPage.pageInfo.endCursor }
        : undefined;
    },
  });

  const nodes = data?.pages.map((page) => page.nodes).flat();

  const loadMore = useMemo(
    () =>
      hasNextPage
        ? () => {
            fetchNextPage();
          }
        : undefined,
    [hasNextPage, fetchNextPage]
  );

  return {
    nodes,
    loading: isFetchingNextPage,
    loadMore,
  };
};

export { VariablesOf };
export default useCollection;
