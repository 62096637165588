import classNames from "classnames";
import { forwardRef } from "react";
import type { ElementType } from "react";

import Alert from "../Alert";
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from "../_PolymorphicType";
import style from "./Field.module.css";
import Input from "./Input";

type TypeProps = (
  | {
      description?: string;
      name: string;
    }
  | {
      description: string;
      name?: string;
    }
) & {
  error: string | undefined;
  suffix?: React.ReactNode;
};

type InputT = typeof Input;

type ContainerProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<C, TypeProps>;

type ContainerComponent = <C extends React.ElementType = InputT>(
  props: ContainerProps<C>
) => React.ReactElement | null;

type DefaultContainerComponent = (
  props: ContainerProps<InputT>
) => React.ReactElement | null;

const Field: DefaultContainerComponent & ContainerComponent = forwardRef(
  function Field<C extends ElementType = InputT>(
    { as, description, error, hidden, suffix, ...props }: ContainerProps<C>,
    ref?: PolymorphicRef<C>
  ) {
    const Component = as || Input;
    return hidden ? (
      <Component hidden ref={ref} {...props} />
    ) : (
      <div // eslint-disable-line react/forbid-elements
        className={classNames(style.field)}
      >
        <label className={style.label} htmlFor={props.id}>
          {description || props.name}
        </label>
        {error && <Alert headline={error} />}
        <div // eslint-disable-line react/forbid-elements
          className={classNames(style.inputContainer)}
        >
          <Component {...props} className={style.input} ref={ref} />
          <span>{suffix}</span>
        </div>
      </div>
    );
  }
);

export default Field;
