import classNames from "classnames";
import { forwardRef } from "react";
import type { ComponentProps, ComponentPropsWithRef } from "react";

import style from "./Input.module.css";

const Input = forwardRef(function Input(
  {
    className,
    type = "text",
    placeholder: _placeholder,
    ...props
  }: {
    type?: "text" | "email" | "password" | "checkbox" | "file";
  } & Pick<
    ComponentProps<"input">,
    | "id"
    | "placeholder"
    | "required"
    | "disabled"
    | "autoComplete"
    | "hidden"
    | "readOnly"
    | "value"
    | "autoFocus"
    | "className"
    | "maxLength"
    | "pattern"
    | "name"
    | "accept"
  >,
  ref: ComponentPropsWithRef<"input">["ref"]
) {
  const placeholder = _placeholder
    ? _placeholder
    : type === "email"
    ? "you@example.com"
    : undefined;
  return (
    <input // eslint-disable-line react/forbid-elements
      ref={ref}
      type={type}
      className={classNames(style.input, className)}
      placeholder={placeholder}
      {...props}
    />
  );
});

export default Input;
