import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Container, Page } from "Component";
import { Navigate, Route, Router as _Router } from "Component/router";
import { Link, NavLink, useSearchParamsState } from "Component/router";
import Login from "View/Login";
import Logout from "View/Logout";
import classNames from "classnames";
import { AlertProvider, useAlert, useConfig } from "hooks";
import { useCollection } from "new/model/organisation";
import { useEffect } from "react";

import Organisations from "./Organisations";
import Routes from "./Routes";
import SshKeyPairs from "./SshKeyPairs";

const paths = {
  organisations: {
    link: {
      to: "/organisations",
      search: {},
      params: {},
    },
  },
  organisation: (organisationId: string) => ({
    domains: {
      link: {
        to: `/organisations/${organisationId}/domains`,
      },
    },
    billing: {
      link: {
        to: `/organisations/${organisationId}/billing`,
      },
    },
    link: {
      to: `/organisations/${organisationId}/edit`,
    },
    domain: (domainId: string) => ({
      link: {
        to: `/organisations/${organisationId}/domains/${domainId}/edit`,
      },
    }),
  }),
  ssh_key_pairs: {
    link: {
      to: "/ssh_key_pairs",
    },
  },
};

const NavBarBillingLink = ({ id }: { id: string }) => {
  const { isSet } = useAlert();
  const alert = isSet("billing");
  return (
    <NavLink
      className={classNames({ alert })}
      {...paths.organisation(id).billing.link}
    >
      billing
    </NavLink>
  );
};

const NavBar = ({ username }: { username: string }) => {
  const [organisationId] = useSearchParamsState("organisationId");
  const { nodes: items } = useCollection();

  return (
    <>
      <ul>
        <li>
          <NavLink {...paths.organisations.link}>organisations</NavLink>
        </li>
      {organisationId && (
        <>
          <li>
            <NavLink {...paths.organisation(organisationId).link}>
              organisation {organisationId}
            </NavLink>
          </li>
          <li>
            <NavLink {...paths.organisation(organisationId).domains.link}>
              domains
            </NavLink>
          </li>
          <li>
            <NavBarBillingLink id={organisationId} />
          </li>
        </>
      )}

        <li>
          <NavLink {...paths.ssh_key_pairs.link}>SSH keys</NavLink>
        </li>
      </ul>
      <ul>
        <li>
          <NavLink to="https://admin.localhost.run/">old site</NavLink>
        </li>
        <li>
          <NavLink to="/logout">logout {username}</NavLink>
        </li>
      </ul>
    </>
  );
};

const RenderAlerts = () => {
  const { renderItems, alertCount } = useAlert();
  return alertCount === 0 ? null : (
    <Container spaced margined>
      {renderItems(({ msg, key }) => (
        <Container key={key} padded purpose="alert">
          {msg}
        </Container>
      ))}
    </Container>
  );
};

const PageDetails = ({ username }: { username?: string }) => (
  <_Router>
    <Page
      title={<Link to="/">LOCALHOST.RUN</Link>}
      nav={!!username && <NavBar username={username} />}
      footer={
        <Container row spaced>
          <Link to="mailto:help@localhost.run" offsite>
            [ email help@localhost.run ]
          </Link>
          <Link to="http://localhost.run/docs" offsite target="_lhrdocs">
            [ documentaion ]
          </Link>
        </Container>
      }
    >
      <RenderAlerts />
      <Container purpose="window">
        {!username ? (
          <Login />
        ) : (
          <>
            <CheckVote username={username} />
            <Router />
          </>
        )}
      </Container>
    </Page>
  </_Router>
);

const TakeAVote = ({ onVote }: { onVote: (_arg0: boolean) => void }) => {
  return (
    <Container spaced style={{ alignItems: "center" }}>
      do you like the new retro site?
      <Container row>
        <Button onClick={() => onVote(true)}>yes</Button>{" "}
        <Button priority="warning" onClick={() => onVote(false)}>
          no
        </Button>
      </Container>
    </Container>
  );
};

const CheckVote = ({ username }: { username: string }) => {
  const { set, clear } = useAlert();
  const {
    lhr: { vote_url: vote_url_base },
  } = useConfig();

  const mutation = useMutation({
    mutationKey: ["vote"],
    mutationFn: async (vote: boolean) => {
      clear("vote");
      const vote_url = new URL(
        `/rwrt/${username}/${vote ? "yes" : "no"}`,
        vote_url_base
      ).href;
      const response = await fetch(vote_url, { method: "POST" });
      if (!response.ok) {
        console.log("vote failed :(");
      }
    },
  });

  const { data: takeAVote } = useQuery(["vote"], async () => {
    try {
      if (vote_url_base !== undefined) {
        const vote_url = new URL(`/rwrt/${username}`, vote_url_base).href;
        const response = await fetch(vote_url);
        if (response.ok) {
          const result = await response.json();
          if (Object.keys(result).length === 0) {
            return true;
          }
        }
      }
    } catch (error) {
      console.log("error checking vote", error);
    }
    return false;
  });
  useEffect(() => {
    if (takeAVote) {
      set("vote", <TakeAVote onVote={mutation.mutate} />);
    }
  }, [takeAVote, set]);
  return null;
};

const Router = () => (
  <Routes>
    <Route index element={<Navigate to="organisations" />} />
    <Route path="organisations/*" element={<Organisations />} />
    <Route path="ssh_key_pairs/*" element={<SshKeyPairs />} />
    <Route
      path="logout"
      element={
        <Logout>
          <Navigate to="/" replace />
        </Logout>
      }
    />
  </Routes>
);

export default PageDetails;
