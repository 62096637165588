import { gql } from "__generated__";
import useMutation, { VariablesOf } from "new/model/useMutation";

const query = gql(`
  mutation createDomain($input: CreateDomainInput!) {
    createDomain(input: $input) {
      domain {
        id
        name
        dns {
          txt {
            active
            target
          }
          alias {
            active
            target
          }
        }
      }
    }
  }
`);

const useCreate = (organisationId: string) => {
  const { mutate, ...rest } = useMutation({
    query,
    resultFn: (data) => data.createDomain.domain,
    keyFn: () => ["organisation", organisationId, "domain"],
  });
  return {
    mutate: (
      props: Omit<VariablesOf<typeof query>["input"], "organisationId">
    ) => mutate({ input: { organisationId, ...props } }),
    ...rest,
  };
};

export default useCreate;
