import { Form } from "Component";
import {
  Alert,
  CodeBlock,
  Container,
  useMultiroleFormAdaptor,
} from "Component";
import { useForm } from "react-hook-form";

import type { ReturnT as DataReturnT } from "../../../DataCallback";
import DnsAlert from "./DnsAlert";

const Edit = ({
  item,
  removeFn,
}: {
  item: {
    id: string;
    name: string;
    dns: {
      txt: {
        active: boolean;
        target: string;
      } | null;
      alias: {
        active: boolean;
        target: string;
      } | null;
    };
  };
  removeFn: () => DataReturnT<undefined>;
}) => {
  const form = useForm<{
    name: string;
    id: string;
    deleteConfirmed?: boolean;
  }>({
    values: {
      name: item.name,
      id: item.id,
    },
  });
  const {
    setError,
    formState: { errors },
  } = form;

  const { formProps, register, registerConfirmDelete } =
    useMultiroleFormAdaptor({
      form,
      onDelete: async () => {
        const result = await removeFn();
        if (!result.success) {
          setError("root.serverError", { message: result.error });
        }
      },
    });

  const sshCmd = `ssh -R ${item.name}:80:localhost:8080 plan@localhost.run`;

  return (
    <Form.Form title="domain" {...formProps}>
      <DnsAlert type="txt" {...item.dns.txt} />
      <DnsAlert type="alias" {...item.dns.alias} />
      <Form.Field {...register("id")} readOnly />
      <Form.Field {...register("name")} readOnly />
      <Form.Field {...registerConfirmDelete()} type="checkbox" />

      {errors.root?.serverError && errors.root?.serverError.message && (
        <Alert headline={errors.root?.serverError.message} />
      )}

      <Container>
        <span>run this command to connect a tunnel to local port 8080</span>
        <CodeBlock code={sshCmd} />
      </Container>
    </Form.Form>
  );
};

export default Edit;
