/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AWSEmail: { input: string; output: string };
  AWSURL: { input: string; output: string };
};

export type BillingIntervalEnum = "month" | "year";

export type CreateDomainInput = {
  name: Scalars["String"]["input"];
  organisationId: Scalars["ID"]["input"];
};

export type CreateDomainPayload = {
  __typename?: "CreateDomainPayload";
  domain: Domain;
};

export type CreateOrganisationInput = {
  name: Scalars["String"]["input"];
};

export type CreateOrganisationMemberInput = {
  email: Scalars["AWSEmail"]["input"];
  organisationId: Scalars["ID"]["input"];
  role: OrganisationMemberRoleEnum;
};

export type CreateOrganisationMemberPayload = {
  __typename?: "CreateOrganisationMemberPayload";
  member: OrganisationMember;
};

export type CreateOrganisationPayload = {
  __typename?: "CreateOrganisationPayload";
  organisation: Organisation;
};

export type CreateSshKeyPairInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  publicKey: Scalars["String"]["input"];
};

export type CreateSshKeyPairPayload = {
  __typename?: "CreateSshKeyPairPayload";
  sshKeyPair: SshKeyPair;
};

export type DeleteDomainInput = {
  id: Scalars["ID"]["input"];
};

export type DeleteDomainPayload = {
  __typename?: "DeleteDomainPayload";
  domainId: Scalars["ID"]["output"];
};

export type DeleteOrganisationInput = {
  id: Scalars["ID"]["input"];
};

export type DeleteOrganisationMemberInput = {
  email: Scalars["AWSEmail"]["input"];
  organisationId: Scalars["ID"]["input"];
};

export type DeleteOrganisationMemberPayload = {
  __typename?: "DeleteOrganisationMemberPayload";
  email: Scalars["AWSEmail"]["output"];
  organisationId: Scalars["ID"]["output"];
};

export type DeleteOrganisationPayload = {
  __typename?: "DeleteOrganisationPayload";
  organisationId: Scalars["ID"]["output"];
};

export type DeleteSshKeyPairInput = {
  id: Scalars["ID"]["input"];
};

export type DeleteSshKeyPairPayload = {
  __typename?: "DeleteSshKeyPairPayload";
  sshKeyPairId: Scalars["ID"]["output"];
};

export type Domain = Node & {
  __typename?: "Domain";
  dns: DomainDns;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type DomainConnection = {
  __typename?: "DomainConnection";
  edges: Array<DomainEdge>;
  pageInfo: PageInfo;
  total: Scalars["Int"]["output"];
};

export type DomainDns = {
  __typename?: "DomainDns";
  alias: Maybe<DomainDnsAlias>;
  txt: Maybe<DomainDnsTxt>;
};

export type DomainDnsAlias = {
  __typename?: "DomainDnsAlias";
  active: Scalars["Boolean"]["output"];
  target: Scalars["String"]["output"];
};

export type DomainDnsTxt = {
  __typename?: "DomainDnsTxt";
  active: Scalars["Boolean"]["output"];
  target: Scalars["String"]["output"];
};

export type DomainEdge = {
  __typename?: "DomainEdge";
  cursor: Maybe<Scalars["String"]["output"]>;
  node: Domain;
};

export type Mutation = {
  __typename?: "Mutation";
  createDomain: CreateDomainPayload;
  createOrganisation: CreateOrganisationPayload;
  createOrganisationMember: CreateOrganisationMemberPayload;
  createSshKeyPair: CreateSshKeyPairPayload;
  deleteDomain: DeleteDomainPayload;
  deleteOrganisation: DeleteOrganisationPayload;
  deleteOrganisationMember: DeleteOrganisationMemberPayload;
  deleteSshKeyPair: DeleteSshKeyPairPayload;
  updateOrganisation: UpdateOrganisationPayload;
};

export type MutationCreateDomainArgs = {
  input: CreateDomainInput;
};

export type MutationCreateOrganisationArgs = {
  input: CreateOrganisationInput;
};

export type MutationCreateOrganisationMemberArgs = {
  input: CreateOrganisationMemberInput;
};

export type MutationCreateSshKeyPairArgs = {
  input: CreateSshKeyPairInput;
};

export type MutationDeleteDomainArgs = {
  input: DeleteDomainInput;
};

export type MutationDeleteOrganisationArgs = {
  input: DeleteOrganisationInput;
};

export type MutationDeleteOrganisationMemberArgs = {
  input: DeleteOrganisationMemberInput;
};

export type MutationDeleteSshKeyPairArgs = {
  input: DeleteSshKeyPairInput;
};

export type MutationUpdateOrganisationArgs = {
  input: UpdateOrganisationInput;
};

export type Node = {
  id: Scalars["ID"]["output"];
};

export type Organisation = Node & {
  __typename?: "Organisation";
  billing: OrganisationBilling;
  domains: DomainConnection;
  id: Scalars["ID"]["output"];
  members: OrganisationMemberConnection;
  name: Scalars["String"]["output"];
};

export type OrganisationDomainsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganisationMembersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganisationBilling = {
  __typename?: "OrganisationBilling";
  active: Scalars["Boolean"]["output"];
  balance: Maybe<Scalars["Float"]["output"]>;
  billingPortalSessionUrl: Scalars["AWSURL"]["output"];
  billingUrl: Maybe<Scalars["AWSURL"]["output"]>;
  card: Maybe<StripePaymentMethodCard>;
  checkoutUrl: Scalars["AWSURL"]["output"];
  needsAttention: Scalars["Boolean"]["output"];
  nextBillAmount: Maybe<Scalars["Float"]["output"]>;
  paymentIntent: Maybe<PaymentIntent>;
  plans: Array<Plans>;
  price: Maybe<Scalars["Int"]["output"]>;
  quantity: Maybe<Scalars["Int"]["output"]>;
  setupIntentClientSecret: Scalars["String"]["output"];
};

export type OrganisationBillingBillingPortalSessionUrlArgs = {
  returnUrl?: InputMaybe<Scalars["AWSURL"]["input"]>;
};

export type OrganisationBillingBillingUrlArgs = {
  returnUrl?: InputMaybe<Scalars["AWSURL"]["input"]>;
};

export type OrganisationBillingCheckoutUrlArgs = {
  interval?: InputMaybe<BillingIntervalEnum>;
  returnUrl?: InputMaybe<Scalars["AWSURL"]["input"]>;
};

export type OrganisationMember = {
  __typename?: "OrganisationMember";
  email: Scalars["AWSEmail"]["output"];
  organisationId: Scalars["ID"]["output"];
  role: OrganisationMemberRoleEnum;
};

export type OrganisationMemberConnection = {
  __typename?: "OrganisationMemberConnection";
  nodes: Array<OrganisationMember>;
  pageInfo: PageInfo;
  total: Scalars["Int"]["output"];
};

export type OrganisationMemberRoleEnum = "admin" | "member";

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor: Maybe<Scalars["String"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  startCursor: Maybe<Scalars["String"]["output"]>;
};

export type PaymentIntent = {
  __typename?: "PaymentIntent";
  client_secret: Maybe<Scalars["String"]["output"]>;
  status: Maybe<PaymentIntentStatusEnum>;
};

export type PaymentIntentStatusEnum =
  | "requires_action"
  | "requires_payment_method"
  | "succeeded";

export type PlanInterval = {
  __typename?: "PlanInterval";
  active: Scalars["Boolean"]["output"];
  interval: PlanIntervalPeriodEnum;
  price: Scalars["Int"]["output"];
};

export type PlanIntervalPeriodEnum = "month" | "year";

export type Plans = {
  __typename?: "Plans";
  features: Array<Scalars["String"]["output"]>;
  intervals: Array<PlanInterval>;
  title: Scalars["String"]["output"];
};

export type Query = {
  __typename?: "Query";
  domain: Domain;
  organisation: Organisation;
  organisationMember: OrganisationMember;
  sshKeyPair: SshKeyPair;
  user: User;
};

export type QueryDomainArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrganisationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrganisationMemberArgs = {
  email: Scalars["AWSEmail"]["input"];
  organisationId: Scalars["ID"]["input"];
};

export type QuerySshKeyPairArgs = {
  id: Scalars["ID"]["input"];
};

export type SshKeyPair = Node & {
  __typename?: "SshKeyPair";
  description: Maybe<Scalars["String"]["output"]>;
  fingerprint: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  privateKey: Maybe<Scalars["String"]["output"]>;
  publicKey: Scalars["String"]["output"];
};

export type StripePaymentMethodCard = {
  __typename?: "StripePaymentMethodCard";
  brand: Scalars["String"]["output"];
  exp_month: Scalars["Int"]["output"];
  exp_year: Scalars["Int"]["output"];
  last4: Scalars["String"]["output"];
};

export type UpdateOrganisationInput = {
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateOrganisationPayload = {
  __typename?: "UpdateOrganisationPayload";
  organisation: Organisation;
};

export type User = Node & {
  __typename?: "User";
  email: Scalars["AWSEmail"]["output"];
  id: Scalars["ID"]["output"];
  organisations: UserOrganisationConnection;
  sshKeyPairs: UserSshKeyPairConnection;
};

export type UserOrganisationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserSshKeyPairsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserOrganisationConnection = {
  __typename?: "UserOrganisationConnection";
  edges: Array<UserOrganisationEdge>;
  pageInfo: PageInfo;
};

export type UserOrganisationEdge = {
  __typename?: "UserOrganisationEdge";
  cursor: Maybe<Scalars["String"]["output"]>;
  isOwner: Scalars["Boolean"]["output"];
  node: Organisation;
};

export type UserSshKeyPairConnection = {
  __typename?: "UserSshKeyPairConnection";
  edges: Array<UserSshKeyPairEdge>;
  pageInfo: PageInfo;
};

export type UserSshKeyPairEdge = {
  __typename?: "UserSshKeyPairEdge";
  cursor: Maybe<Scalars["String"]["output"]>;
  node: SshKeyPair;
};

export type OrganisationBillingQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  interval?: InputMaybe<BillingIntervalEnum>;
  returnUrl?: InputMaybe<Scalars["AWSURL"]["input"]>;
}>;

export type OrganisationBillingQuery = {
  __typename?: "Query";
  organisation: {
    __typename?: "Organisation";
    id: string;
    name: string;
    billing: {
      __typename?: "OrganisationBilling";
      active: boolean;
      needsAttention: boolean;
      checkoutUrl: string;
      billingPortalSessionUrl: string;
    };
  };
};

export type OrganisationsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]["input"]>;
  first: Scalars["Int"]["input"];
}>;

export type OrganisationsQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    id: string;
    email: string;
    organisations: {
      __typename?: "UserOrganisationConnection";
      edges: Array<{
        __typename?: "UserOrganisationEdge";
        node: {
          __typename?: "Organisation";
          id: string;
          name: string;
          billing: {
            __typename?: "OrganisationBilling";
            active: boolean;
            needsAttention: boolean;
          };
        };
      }>;
      pageInfo: {
        __typename?: "PageInfo";
        hasNextPage: boolean;
        endCursor: string | null;
      };
    };
  };
};

export type CreateOrganisationMutationVariables = Exact<{
  input: CreateOrganisationInput;
}>;

export type CreateOrganisationMutation = {
  __typename?: "Mutation";
  createOrganisation: {
    __typename?: "CreateOrganisationPayload";
    organisation: { __typename?: "Organisation"; id: string; name: string };
  };
};

export type OrganisationQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type OrganisationQuery = {
  __typename?: "Query";
  organisation: {
    __typename?: "Organisation";
    id: string;
    name: string;
    billing: {
      __typename?: "OrganisationBilling";
      active: boolean;
      needsAttention: boolean;
    };
  };
};

export type DeleteOrganisationMutationVariables = Exact<{
  input: DeleteOrganisationInput;
}>;

export type DeleteOrganisationMutation = {
  __typename?: "Mutation";
  deleteOrganisation: {
    __typename?: "DeleteOrganisationPayload";
    organisationId: string;
  };
};

export type UpdateOrganisationMutationVariables = Exact<{
  input: UpdateOrganisationInput;
}>;

export type UpdateOrganisationMutation = {
  __typename?: "Mutation";
  updateOrganisation: {
    __typename?: "UpdateOrganisationPayload";
    organisation: { __typename?: "Organisation"; id: string; name: string };
  };
};

export type DomainsQueryVariables = Exact<{
  organisationId: Scalars["ID"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type DomainsQuery = {
  __typename?: "Query";
  organisation: {
    __typename?: "Organisation";
    id: string;
    domains: {
      __typename?: "DomainConnection";
      edges: Array<{
        __typename?: "DomainEdge";
        node: {
          __typename?: "Domain";
          id: string;
          name: string;
          dns: {
            __typename?: "DomainDns";
            txt: {
              __typename?: "DomainDnsTxt";
              active: boolean;
              target: string;
            } | null;
            alias: {
              __typename?: "DomainDnsAlias";
              active: boolean;
              target: string;
            } | null;
          };
        };
      }>;
      pageInfo: {
        __typename?: "PageInfo";
        hasNextPage: boolean;
        endCursor: string | null;
      };
    };
  };
};

export type CreateDomainMutationVariables = Exact<{
  input: CreateDomainInput;
}>;

export type CreateDomainMutation = {
  __typename?: "Mutation";
  createDomain: {
    __typename?: "CreateDomainPayload";
    domain: {
      __typename?: "Domain";
      id: string;
      name: string;
      dns: {
        __typename?: "DomainDns";
        txt: {
          __typename?: "DomainDnsTxt";
          active: boolean;
          target: string;
        } | null;
        alias: {
          __typename?: "DomainDnsAlias";
          active: boolean;
          target: string;
        } | null;
      };
    };
  };
};

export type DomainQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DomainQuery = {
  __typename?: "Query";
  domain: {
    __typename?: "Domain";
    id: string;
    name: string;
    dns: {
      __typename?: "DomainDns";
      txt: {
        __typename?: "DomainDnsTxt";
        active: boolean;
        target: string;
      } | null;
      alias: {
        __typename?: "DomainDnsAlias";
        active: boolean;
        target: string;
      } | null;
    };
  };
};

export type DeleteDomainMutationVariables = Exact<{
  input: DeleteDomainInput;
}>;

export type DeleteDomainMutation = {
  __typename?: "Mutation";
  deleteDomain: { __typename?: "DeleteDomainPayload"; domainId: string };
};

export type MembersQueryVariables = Exact<{
  organisationId: Scalars["ID"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type MembersQuery = {
  __typename?: "Query";
  organisation: {
    __typename?: "Organisation";
    id: string;
    members: {
      __typename?: "OrganisationMemberConnection";
      nodes: Array<{
        __typename?: "OrganisationMember";
        email: string;
        role: OrganisationMemberRoleEnum;
      }>;
      pageInfo: {
        __typename?: "PageInfo";
        hasNextPage: boolean;
        endCursor: string | null;
      };
    };
  };
};

export type CreateOrganisationMemberMutationVariables = Exact<{
  input: CreateOrganisationMemberInput;
}>;

export type CreateOrganisationMemberMutation = {
  __typename?: "Mutation";
  createOrganisationMember: {
    __typename?: "CreateOrganisationMemberPayload";
    member: {
      __typename?: "OrganisationMember";
      organisationId: string;
      email: string;
      role: OrganisationMemberRoleEnum;
    };
  };
};

export type OrganisationMemberQueryVariables = Exact<{
  organisationId: Scalars["ID"]["input"];
  email: Scalars["AWSEmail"]["input"];
}>;

export type OrganisationMemberQuery = {
  __typename?: "Query";
  organisationMember: {
    __typename?: "OrganisationMember";
    email: string;
    role: OrganisationMemberRoleEnum;
  };
};

export type DeleteOrganisationMemberMutationVariables = Exact<{
  input: DeleteOrganisationMemberInput;
}>;

export type DeleteOrganisationMemberMutation = {
  __typename?: "Mutation";
  deleteOrganisationMember: {
    __typename?: "DeleteOrganisationMemberPayload";
    organisationId: string;
    email: string;
  };
};

export type Organisations2QueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Organisations2Query = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    id: string;
    email: string;
    organisations: {
      __typename?: "UserOrganisationConnection";
      edges: Array<{
        __typename?: "UserOrganisationEdge";
        node: {
          __typename?: "Organisation";
          id: string;
          name: string;
          billing: {
            __typename?: "OrganisationBilling";
            active: boolean;
            needsAttention: boolean;
          };
        };
      }>;
      pageInfo: {
        __typename?: "PageInfo";
        hasNextPage: boolean;
        endCursor: string | null;
      };
    };
  };
};

export type SshKeyPairsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SshKeyPairsQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    id: string;
    email: string;
    sshKeyPairs: {
      __typename?: "UserSshKeyPairConnection";
      edges: Array<{
        __typename?: "UserSshKeyPairEdge";
        node: {
          __typename?: "SshKeyPair";
          id: string;
          description: string | null;
          publicKey: string;
          fingerprint: string | null;
        };
      }>;
      pageInfo: {
        __typename?: "PageInfo";
        hasNextPage: boolean;
        endCursor: string | null;
      };
    };
  };
};

export type CreateSshKeyPairMutationVariables = Exact<{
  input: CreateSshKeyPairInput;
}>;

export type CreateSshKeyPairMutation = {
  __typename?: "Mutation";
  createSshKeyPair: {
    __typename?: "CreateSshKeyPairPayload";
    sshKeyPair: { __typename?: "SshKeyPair"; id: string };
  };
};

export type SshKeyPairQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type SshKeyPairQuery = {
  __typename?: "Query";
  sshKeyPair: {
    __typename?: "SshKeyPair";
    id: string;
    description: string | null;
    publicKey: string;
    fingerprint: string | null;
  };
};

export type DeleteSshKeyPairMutationVariables = Exact<{
  input: DeleteSshKeyPairInput;
}>;

export type DeleteSshKeyPairMutation = {
  __typename?: "Mutation";
  deleteSshKeyPair: {
    __typename?: "DeleteSshKeyPairPayload";
    sshKeyPairId: string;
  };
};

export const OrganisationBillingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "organisationBilling" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "interval" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "BillingIntervalEnum" },
          },
          defaultValue: { kind: "EnumValue", value: "year" },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "returnUrl" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "AWSURL" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organisation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "active" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "needsAttention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutUrl" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "interval" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "interval" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "returnUrl" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "returnUrl" },
                            },
                          },
                        ],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "billingPortalSessionUrl",
                        },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "returnUrl" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "returnUrl" },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OrganisationBillingQuery,
  OrganisationBillingQueryVariables
>;
export const OrganisationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "organisations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organisations" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "after" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billing" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "active",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "needsAttention",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hasNextPage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endCursor" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganisationsQuery, OrganisationsQueryVariables>;
export const CreateOrganisationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createOrganisation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateOrganisationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOrganisation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organisation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrganisationMutation,
  CreateOrganisationMutationVariables
>;
export const OrganisationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "organisation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organisation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "active" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "needsAttention" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganisationQuery, OrganisationQueryVariables>;
export const DeleteOrganisationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteOrganisation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteOrganisationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteOrganisation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organisationId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteOrganisationMutation,
  DeleteOrganisationMutationVariables
>;
export const UpdateOrganisationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateOrganisation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateOrganisationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateOrganisation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organisation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrganisationMutation,
  UpdateOrganisationMutationVariables
>;
export const DomainsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "domains" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organisationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organisation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organisationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "domains" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "after" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: { kind: "IntValue", value: "20" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dns" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "txt" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "active",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "target",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "alias",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "active",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "target",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hasNextPage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endCursor" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DomainsQuery, DomainsQueryVariables>;
export const CreateDomainDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createDomain" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateDomainInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createDomain" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "domain" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dns" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "txt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "active" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "target" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "alias" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "active" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "target" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDomainMutation,
  CreateDomainMutationVariables
>;
export const DomainDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "domain" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "domain" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dns" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "txt" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "active" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "alias" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "active" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DomainQuery, DomainQueryVariables>;
export const DeleteDomainDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteDomain" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteDomainInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteDomain" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "domainId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDomainMutation,
  DeleteDomainMutationVariables
>;
export const MembersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "members" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organisationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organisation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organisationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "members" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "after" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: { kind: "IntValue", value: "20" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "role" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hasNextPage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endCursor" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MembersQuery, MembersQueryVariables>;
export const CreateOrganisationMemberDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createOrganisationMember" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateOrganisationMemberInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOrganisationMember" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "member" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organisationId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "role" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrganisationMemberMutation,
  CreateOrganisationMemberMutationVariables
>;
export const OrganisationMemberDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "organisationMember" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organisationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AWSEmail" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organisationMember" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organisationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organisationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "role" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OrganisationMemberQuery,
  OrganisationMemberQueryVariables
>;
export const DeleteOrganisationMemberDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteOrganisationMember" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteOrganisationMemberInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteOrganisationMember" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organisationId" },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteOrganisationMemberMutation,
  DeleteOrganisationMemberMutationVariables
>;
export const Organisations2Document = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "organisations2" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organisations" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "after" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: { kind: "IntValue", value: "20" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billing" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "active",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "needsAttention",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hasNextPage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endCursor" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Organisations2Query, Organisations2QueryVariables>;
export const SshKeyPairsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "sshKeyPairs" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sshKeyPairs" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "after" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: { kind: "IntValue", value: "20" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "publicKey" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "fingerprint",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hasNextPage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endCursor" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SshKeyPairsQuery, SshKeyPairsQueryVariables>;
export const CreateSshKeyPairDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createSshKeyPair" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateSshKeyPairInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createSshKeyPair" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sshKeyPair" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSshKeyPairMutation,
  CreateSshKeyPairMutationVariables
>;
export const SshKeyPairDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "sshKeyPair" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sshKeyPair" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "publicKey" } },
                { kind: "Field", name: { kind: "Name", value: "fingerprint" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SshKeyPairQuery, SshKeyPairQueryVariables>;
export const DeleteSshKeyPairDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteSshKeyPair" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteSshKeyPairInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteSshKeyPair" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sshKeyPairId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSshKeyPairMutation,
  DeleteSshKeyPairMutationVariables
>;
