import { Collection, Container, NotFound } from "Component";
import {
  Link,
  NavLink,
  Navigate,
  Outlet,
  Route,
  useHref,
  useParams,
  useSearchParamsState,
} from "Component/router";
import OrganisationView from "View/Organisation";
import { useAlert } from "hooks";
import {
  useItem,
  useCreate,
  useCollection,
} from "new/model/organisation";
import {
  useUpdate as useBillingUpdate,
} from "new/model/organisation/billing";

import { useEffect, useMemo, useState } from "react";
import type { ComponentProps } from "react";
import { useResolvedPath } from "react-router-dom";
import { Navigate as OGNavigate } from "react-router-dom";

import Intersection from "../Intersection";
import Routes from "../Routes";
import Domains from "./Domains";
import Members from "./Members";
import useFlip from "../useFlip";

const Billing = ({ id }: { id: string }) => {
  const returnUrl = new URL(
    useHref("..", { relative: "path" }),
    window.location.origin
  );

  const { update, node: data, isLoading } = useBillingUpdate(id);
  useEffect(() => {
    update({returnUrl: returnUrl.toString(), interval: "month"});
  }, []);

  if (isLoading) {
    return <Container>loading billing link...</Container>;
  }
  if (!data) {
    return <NotFound message={`organisation id ${id} not found`} />;
  }
  if (!data.billing.active) {
    window.location.replace(data.billing.checkoutUrl);
  } else {
    window.location.replace(data.billing.billingPortalSessionUrl);
  }
  return <Container>redirecting you to Stripe billing</Container>;
};

const OrganisationsItem = (
  props: ComponentProps<typeof OrganisationView.Collection.Item>
) => (
  <Container<typeof Link> as={Link} to={props.id}>
    <OrganisationView.Collection.Item {...props} />
  </Container>
);

const CreateOrganisation = () => {
  const { mutate: _create } = useCreate();

  const [createdId, setCreatedId] = useState<string>();

  return createdId ? (
    <Navigate to={`../${createdId}`} />
  ) : (
    <OrganisationView.Item.Create
      createFn={async ({ name }) => {
        try {
          const { id } = await _create({ name });
          setCreatedId(id);
          return { success: true, data: undefined };
        } catch (error) {
          return { success: false, error: error as string };
        }
      }}
    />
  );
};

const Organisations = () => {
  const useData = () => useFlip(useCollection());
  return (
  <Intersection
    collection={
      <Collection useData={useData} RenderItem={OrganisationsItem} />
    }
    item={<Organisation />}
    create={<CreateOrganisation />}
  />
);
  };

const Organisation = () => {
  const { id } = useParams() as { id: string };
  const billingHealthy = useBillingCheckHealthy({ id });
  return (
    <Routes>
      <Route element={<OrganisationPage id={id} />}>
        <Route
          index
          element={<Navigate to={billingHealthy ? "domains" : "edit"} />}
        />
        <Route path="edit" element={<EditOrganisation id={id} />} />
        <Route path="domains/*" element={<Domains id={id} />} />
        <Route path="members/*" element={<Members id={id} />} />
        <Route path="billing" element={<Billing id={id} />} />
      </Route>
    </Routes>
  );
};

const OrganisationPage = ({ id }: { id: string }) => {
  useOrganisationIdStash({ id });
  return (
    <>
      <nav>
        <NavLink to="domains">domains</NavLink>
        <NavLink to="members">members</NavLink>
      </nav>
      <Outlet />
    </>
  );
};

const useBillingCheckHealthy = ({ id }: { id: string }) => {
  const { node: data } = useItem(id);
  const { set, clear } = useAlert();
  const billingPath = useResolvedPath("./billing");
  const billingLink = useMemo(
    () => <Link to={billingPath.pathname}>Billing needs attention</Link>,
    [billingPath.pathname]
  );
  const billingHealthy =
    data === undefined
      ? undefined
      : data.billing.active && !data.billing.needsAttention;

  useEffect(() => {
    if (billingHealthy) {
      clear("billing");
    } else {
      set("billing", billingLink);
      return () => clear("billing");
    }
  }, [data?.billing.needsAttention, billingHealthy, billingLink, clear, set]);
  return billingHealthy;
};

const useOrganisationIdStash = ({ id }: { id: string }) => {
  const [selectedOrganisationId, setSelectedOrganisationId] =
    useSearchParamsState("organisationId");

  useEffect(() => {
    if (id !== selectedOrganisationId) {
      setSelectedOrganisationId(id);
    }
    return;
  }, [selectedOrganisationId, id, setSelectedOrganisationId]);
};

const EditOrganisation = ({ id }: { id: string }) => {
  const { node: data, remove: _remove, update: _update } = useItem(id);

  const [isDeleted, setIsDeleted] = useState(false);

  const update = async ({ name }: { name: string }) => {
    try {
      await _update({ name });
      setIsDeleted(true);
      return {
        success: true as const,
        data: undefined,
      };
    } catch (error) {
      return {
        success: false as const,
        error: error as string,
      };
    }
  };

  const remove = async () => {
    try {
      await _remove();
      setIsDeleted(true);
      return {
        success: true as const,
        data: undefined,
      };
    } catch (error) {
      return {
        success: false as const,
        error: error as string,
      };
    }
  };

  return isDeleted || data === undefined ? (
    <OGNavigate to="../../" />
  ) : (
    // <Navigate to="deleted" />
    <OrganisationView.Item.Edit
      item={data}
      updateFn={update}
      removeFn={remove}
    />
  );
};

export default Organisations;
