import { useSearchParams } from "react-router-dom";

const useSearchParamsState = (
  searchParamName: string
  // defaultValue?: string
): readonly [
  searchParamsState: string | undefined,
  setSearchParamsState: (newState: string | undefined) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsState = searchParams.get(searchParamName);

  const setSearchParamsState = (newState: string | undefined) => {
    setSearchParams(
      (searchParams) =>
        new URLSearchParams([
          ...[...searchParams.entries()].filter(([k]) => k !== searchParamName),
          ...(newState ? [[searchParamName, newState]] : []),
        ])
    );
  };
  return [
    searchParamsState === null ? undefined : searchParamsState,
    setSearchParamsState,
  ];
};

export default useSearchParamsState;
