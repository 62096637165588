import { NotFound } from "Component";
import { Route, Routes as _Routes } from "Component/router";
import type { ReactNode } from "react";

const Routes = ({ children }: { children: ReactNode }) => (
  <_Routes>
    {children}
    <Route path="*" element={<NotFound message="This url doesn't exist" />} />
  </_Routes>
);

export default Routes;
