import React from "react";

// import style from "./Page.module.css";

const Page = ({
  title,
  nav,
  children,
  footer,
}: {
  title: React.ReactNode;
  nav?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
}) => (
  <>
    <header>
      <h1>{title}</h1>
      {nav && <nav>{nav}</nav>}
    </header>
    <main>{children}</main>
    {footer && <footer>{footer}</footer>}
  </>
);

export default Page;
