import { Auth } from "@aws-amplify/auth";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

/*
 * this module should be in models
 */

const getRandomString = (bytes: number) => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join("");
};

const intToHex = (nr: number) => {
  return nr.toString(16).padStart(2, "0");
};

const useAuth = () => {
  const [signInChallenge, setSignInChallenge] = useState<
    { challengeParam?: { code: string } } | undefined
  >();
  const {
    mutateAsync: sendEmailAsync,
    error: signInError,
    data: _signInChallenge,
  } = useMutation({
    mutationFn: ({ email }: { email: string; signup?: boolean }) =>
      Auth.signIn(email).catch((error) =>
        error?.code === "UserNotFoundException"
          ? Auth.signUp({
              username: email,
              password: getRandomString(30),
            }).then(() => Auth.signIn(email))
          : Promise.reject(error)
      ),
  });

  useEffect(() => {
    setSignInChallenge(_signInChallenge);
  }, [setSignInChallenge, _signInChallenge]);

  const emailError = signInError
    ? (signInError as { message?: string; code?: string }).message ||
      "signin error"
    : undefined;

  const {
    mutateAsync: sendOtpAsync,
    error: sendOtpError,
    data: sendOtpData,
  } = useMutation({
    mutationFn: ({ challenge, otp }: { challenge: object; otp: string }) =>
      Auth.sendCustomChallengeAnswer(challenge, otp),
  });

  const otpError = sendOtpError
    ? (sendOtpError as { message?: string }).message
    : sendOtpData && !sendOtpData?.signInSession
    ? "incorrect code??"
    : undefined;

  return {
    reset: () => {
      setSignInChallenge(undefined);
    },
    ...(!signInChallenge
      ? {
          sendEmail: async (email: string) => {
            await sendEmailAsync({ email, signup: false }).catch(
              (error) => error
            );
          },
        }
      : {}),
    ...(signInChallenge
      ? {
          sendOtp: async (otp: string) => {
            await sendOtpAsync({ challenge: signInChallenge, otp }).catch(
              (error) => error
            );
          },
        }
      : {}),

    expectedOtp: signInChallenge?.challengeParam?.code,
    errors: {
      email: emailError,
      otp: otpError,
    },
  };
};

export default useAuth;
