import { Container, useMultiroleFormAdaptor } from "Component";
import { Alert, Form } from "Component";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import type { ReturnT as DataReturnT } from "../../DataCallback";

const Create = ({
  createFn,
}: {
  createFn: ({ name }: { name: string }) => DataReturnT<unknown>;
}) => {
  const form = useForm<{
    name: string;
    deleteConfirmed?: boolean;
    submitConfirmed?: boolean;
  }>({
    defaultValues: {
      name: "",
      deleteConfirmed: false,
      submitConfirmed: true,
    },
  });
  const {
    setError,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const { formProps, register, registerConfirmSubmit } =
    useMultiroleFormAdaptor({
      form,
      onSubmit: async ({ name }) => {
        const result = await createFn({ name });
        if (!result.success) {
          setError("root.serverError", { message: result.error });
        }
      },
    });

  return (
    <Form.Form title="create organisation" {...formProps}>
      <Form.Field
        {...register("name", {
          required: "a name is required",
        })}
        autoFocus
      />

      {errors.root?.serverError && errors.root?.serverError.message && (
        <Alert headline={errors.root?.serverError.message} />
      )}
    </Form.Form>
  );
};
export default Create;
