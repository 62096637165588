import classNames from "classnames";
import React from "react";

import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from "../_PolymorphicType";
import style from "./Container.module.css";

type TypeProps = {
  border?: "none" | "single" | "double" | true;
  row?: boolean;
  spaced?: boolean;
  padded?: boolean | "vertical" | "both";
  margined?: boolean | "vertical" | "both";
  purpose?: "alert" | "window";
};

type ContainerProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<C, TypeProps>;

type ContainerComponent = <C extends React.ElementType = "div">(
  props: ContainerProps<C>
) => React.ReactElement | null;

type DefaultContainerComponent = (
  props: ContainerProps<"div">
) => React.ReactElement | null;

const Container: DefaultContainerComponent & ContainerComponent =
  React.forwardRef(function Container<C extends React.ElementType = "div">(
    {
      as,
      row = false,
      border = "none",
      spaced = false,
      padded = false,
      margined = false,
      purpose,
      className,
      ...props
    }: ContainerProps<C>,
    ref?: PolymorphicRef<C>
  ) {
    const Component = as || "div";
    return (
      <Component // eslint-disable-line react/forbid-elements
        ref={ref}
        className={classNames([
          style.container,
          {
            [style.border]: border !== "none",
            [style["border-single"]]: border === "single",
            [style.spaced]: spaced,
            [style.row]: row,
            [style["padded-horizontal"]]: padded === true || padded === "both",
            [style["padded-vertical"]]:
              padded === "vertical" || padded === "both",
            [style["margined-horizontal"]]:
              margined === true || margined === "both",
            [style["margined-vertical"]]:
              margined === "vertical" || margined === "both",
            alert: purpose === "alert",
            [style.window]: purpose === "window",
          },
          className,
        ])}
        {...props}
      />
    );
  });

export default Container;
