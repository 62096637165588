import { CollectionItem, Container } from "Component";
import classNames from "classnames";

const Item = ({ email, role }: { email: string; role: "admin" | "member" }) => {
  return (
    <CollectionItem id={email}>
      {/* <Container row style={{ flex: 1 }}>
        <span
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {email}
        </span>
      </Container> */}
      {/* <Container row>
        <span>{role}</span>
      </Container> */}
    </CollectionItem>
  );
};

export default Item;
