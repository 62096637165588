import { Collection } from "Component";
import { Link, Navigate, Route, useParams } from "Component/router";
import DomainView from "View/Organisation/Domain";
import { useState } from "react";
import {useCollection, useCreate, useItem} from "new/model/organisation/domain";

import Intersection from "../../Intersection";
import Routes from "../../Routes";
import useFlip from "../../useFlip";

const Domain = ({ organisationId }: { organisationId: string }) => {
  const { id } = useParams() as { id: string };
  return (
    <Routes>
      <Route index element={<Navigate to="edit" />} />
      <Route
        path="edit"
        element={<EditDomain organisationId={organisationId} id={id} />}
      />
    </Routes>
  );
};

const CreateDomain = ({ organisationId }: { organisationId: string }) => {
  const { mutate: _create } = useCreate(organisationId);

  const [createdId, setCreatedId] = useState<string>();

  return createdId ? (
    <Navigate to={`../${createdId}`} />
  ) : (
    <DomainView.Item.Create
      createFn={async ({ name }) => {
        try {
          const { id } = await _create({ name });
          setCreatedId(id);
          return { success: true, data: undefined };
        } catch (error) {
          return { success: false, error: error as string };
        }
      }}
    />
  );
};

const Domains = ({ id }: { id: string }) => {
  const useData = () => useFlip(useCollection(id));
  return (
    <Intersection
      collection={
        <Collection
          RenderItem={(props) => (
            <Link to={props.id} style={{ width: "100%" }}>
              <DomainView.Collection.Item {...props} />
            </Link>
          )}
          useData={useData}
        />
      }
      item={<Domain organisationId={id} />}
      create={<CreateDomain organisationId={id} />}
    />
  );
};

const EditDomain = ({
  organisationId,
  id,
}: {
  organisationId: string;
  id: string;
}) => {
  const { node: data, remove: _remove } = useItem(organisationId, id);

  const [isDeleted, setIsDeleted] = useState(false);

  const remove = async () => {
    try {
      await _remove();
      setIsDeleted(true);
      return {
        success: true as const,
        data: undefined,
      };
    } catch (error) {
      return {
        success: false as const,
        error: error as string,
      };
    }
  };

  return isDeleted || data === undefined ? (
    <Navigate to="../../" />
  ) : (
    <DomainView.Item.Edit item={data} removeFn={remove} />
  );
};

export default Domains;
