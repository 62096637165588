import { Container, useMultiroleFormAdaptor } from "Component";
import { Alert, Form } from "Component";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import type { ReturnT as DataReturnT } from "../../../DataCallback";

const Create = ({
  createFn,
}: {
  createFn: ({ name }: { name: string }) => DataReturnT<undefined>;
}) => {
  const form = useForm<{
    name: string;
    isLhrRocks: boolean;
    deleteConfirmed?: boolean;
    submitConfirmed?: boolean;
  }>({
    defaultValues: {
      isLhrRocks: true,
      name: "",
      deleteConfirmed: false,
      submitConfirmed: false,
    },
  });
  const {
    setError,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const { formProps, register, registerConfirmSubmit } =
    useMultiroleFormAdaptor({
      form,
      onSubmit: async ({ name: _name, isLhrRocks }) => {
        const name = isLhrRocks ? `${_name}.lhr.rocks` : _name;
        const result = await createFn({ name });
        if (!result.success) {
          setError("root.serverError", { message: result.error });
        }
      },
    });

  const isLhrRocks = watch("isLhrRocks");

  useEffect(
    () =>
      watch(({ name, isLhrRocks }) => {
        if (name?.includes(".") && isLhrRocks) {
          setValue("isLhrRocks", false);
        }
        if (!name?.includes(".") && !isLhrRocks) {
          setValue("isLhrRocks", true);
        }
      }).unsubscribe,
    [watch, setValue]
  );

  return (
    <Form.Form title="create domain" {...formProps}>
      <Form.Field
        {...register("name", {
          required: "a domain name is required",
          pattern: {
            value: isLhrRocks
              ? /^[a-z0-9]{1}[a-z0-9-]{0,61}[a-z0-9]$/
              : /^([a-z0-9]{1}[a-z0-9-]{0,61}[a-z0-9].){1,125}[a-z0-9]{1}[a-z0-9-]{0,61}[a-z0-9]$/,
            message: "a valid domain name is required",
          },
        })}
        description="enter lhr.rocks or your own domain name"
        suffix={isLhrRocks ? ".lhr.rocks" : undefined}
        autoFocus
      />
      {isLhrRocks && (
        <Container>
          lhr.rocks domains are ready to be used immediately without DNS setup
        </Container>
      )}

      {errors.root?.serverError && errors.root?.serverError.message && (
        <Alert headline={errors.root?.serverError.message} />
      )}

      <Form.Field
        {...registerConfirmSubmit()}
        type="checkbox"
        description="add another custom domain subscription to your account?"
      />
      <Form.Field {...register("isLhrRocks")} type="checkbox" readOnly hidden />
    </Form.Form>
  );
};
export default Create;
