import { gql } from "__generated__";
import useCollection_ from "new/model/useCollection";

const query = gql(`
  query organisations2($after: String) {
    user {
      id
      email
      organisations(after: $after, first: 20) {
        edges {
          node {
            id
            name
            billing {
              active
              needsAttention
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`);

const useCollection = () => {
  const result = useCollection_({
    query,
    keyFn: (data) => ["organisation"],
    resultFn: (data) => {
      const { pageInfo, edges } = data.user.organisations;
      return {
        pageInfo,
        nodes: edges.map(({ node }) => node),
      };
    },
  });
  return result;
};

export default useCollection;
