import { gql } from "__generated__";

import useMutation, { VariablesOf } from "../useMutation";

const query = gql(`
  mutation deleteSshKeyPair($input: DeleteSshKeyPairInput!) {
    deleteSshKeyPair(input: $input) {
      sshKeyPairId
    }
  }
`);

const useRemove = () => {
  const { mutate, ...rest } = useMutation({
    query,
    resultFn: (data) => data.deleteSshKeyPair,
    keyFn: ({ sshKeyPairId }) => ["sshkey", sshKeyPairId],
  });
  return {
    mutate: (props: VariablesOf<typeof query>["input"]) =>
      mutate({ input: props }),
    ...rest,
  };
};

export default useRemove;
