import classNames from "classnames";
import type { ComponentProps } from "react";
import { NavLink as _NavLink, useLocation } from "react-router-dom";

import Container from "../../Container";

const ContainerNavLink = (
  props: ComponentProps<typeof Container<typeof _NavLink>>
) => <Container<typeof _NavLink> as={_NavLink} {...props} />;

const NavLink = ({
  to,
  className,
  ...props
}: {
  to: string;
  className?: string;
} & Pick<ComponentProps<typeof ContainerNavLink>, "children">) => {
  const { search } = useLocation();
  return (
    <ContainerNavLink
      className={classNames("link", className)}
      end
      to={to + search}
      {...props}
    />
  );
};

export default NavLink;
