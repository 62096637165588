import classNames from "classnames";
import React from "react";

import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from "../_PolymorphicType";

type TypeProps = {
  disabled?: boolean;
  priority?: "primary" | "secondary" | "danger" | "warning";
};

type ButtonProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  TypeProps
>;

type ButtonComponent = <C extends React.ElementType = "button">(
  props: ButtonProps<C>
) => React.ReactElement | null;

type DefaultButtonComponent = (
  props: ButtonProps<"button">
) => React.ReactElement | null;

const Button: DefaultButtonComponent & ButtonComponent = React.forwardRef(
  function Button<C extends React.ElementType = "button">(
    {
      as,
      priority = "primary",
      disabled = false,
      className,
      ...props
    }: ButtonProps<C>,
    ref?: PolymorphicRef<C>
  ) {
    const Component = as || "button";
    return (
      <Component
        ref={ref}
        className={classNames(
          "button",
          priority,
          {
            disabled,
          },
          className
        )}
        disabled={disabled}
        {...props}
      />
    );
  }
);

export default Button;
