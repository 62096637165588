import { CollectionItem, Container } from "Component";
import classNames from "classnames";

const Item = ({
  id,
  name,
  dns,
}: {
  id: string;
  name: string;
  dns: { txt?: { active: boolean } | null; alias?: { active: boolean } | null };
}) => {
  const alert = !dns.alias?.active || !dns.txt?.active;
  const success = !alert;
  return (
    <CollectionItem id={id}>
      <Container row style={{ flex: 1 }}>
        <span
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {name}
        </span>
      </Container>
      <Container row>
        <span
          className={classNames({
            alert,
            success,
          })}
        >
          {success ? "healthy" : "unhealthy"}
        </span>
      </Container>
    </CollectionItem>
  );
};

export default Item;
