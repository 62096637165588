import { gql } from "__generated__";

import useMutation, { VariablesOf } from "../useMutation";

const query = gql(`
  mutation createSshKeyPair($input: CreateSshKeyPairInput!) {
    createSshKeyPair(input: $input) {
      sshKeyPair {
        id
      }
    }
  }
`);

const useCreate = () => {
  const { mutate, ...rest } = useMutation({
    query,
    resultFn: (data) => data.createSshKeyPair.sshKeyPair,
    keyFn: (data) => ["sshkey", data.id],
  });
  return {
    mutate: (props: VariablesOf<typeof query>["input"]) =>
      mutate({ input: props }),
    ...rest,
  };
};

export default useCreate;
