import { createContext, useCallback, useContext, useState } from "react";
import type { Dispatch, ReactNode, SetStateAction } from "react";

const AlertRecords = createContext<
  [
    { id: string; msg: ReactNode }[],
    Dispatch<SetStateAction<{ id: string; msg: ReactNode }[]>>
  ]
>([[], () => undefined]);

const useAlert = () => {
  const [alertsState, setAlertsState] = useContext(AlertRecords);
  const clear = useCallback(
    (id: string) =>
      setAlertsState((prev) => prev.filter(({ id: _id }) => _id !== id)),
    [setAlertsState]
  );
  const set = useCallback(
    (id: string, msg: ReactNode) => {
      setAlertsState((prev) => [
        ...prev.filter(({ id: _id }) => _id !== id),
        { id, msg },
      ]);
      return () => clear(id);
    },
    [setAlertsState]
  );
  const isSet = useCallback(
    (id: string) => alertsState.some(({ id: _id }) => _id === id),
    [alertsState]
  );
  return {
    set,
    clear,
    isSet,
    alertCount: alertsState.length,
    renderItems: (
      cb: ({ msg, key }: { msg: ReactNode; key: string }) => JSX.Element
    ) => alertsState.map(({ msg, id }) => cb({ msg, key: id })),
  };
};

const AlertProvider = (props: { children: React.ReactNode }) => {
  const alertsState = useState<{ id: string; msg: ReactNode }[]>([]);
  return <AlertRecords.Provider value={alertsState} {...props} />;
};

// const AlertProvider = ({ children }: { children: React.ReactNode }) => {
//   const alertsState = useState<{id: string; msg: ReactNode}[]>([]);
//   return AlertRecords.Provider({value: alertsState, children});
// };

export { AlertProvider, useAlert };
