import { Form } from "Component";
import { useMultiroleFormAdaptor } from "Component";
import { useForm } from "react-hook-form";

import type { ReturnT as DataReturnT } from "../../DataCallback";

const Edit = ({
  item,
  removeFn,
}: {
  item: {
    id: string;
    description: string | null;
    publicKey: string;
    fingerprint: string | null;
  };
  removeFn: () => DataReturnT<undefined>;
}) => {
  const form = useForm<{
    id: string;
    description?: string;
    publicKey: string;
    fingerprint?: string;
    deleteConfirmed?: boolean;
  }>({
    values: {
      id: item.id,
      description: item.description || undefined,
      publicKey: item.publicKey,
      fingerprint: item.fingerprint || undefined,
    },
  });

  const { setError } = form;

  const { formProps, register, registerConfirmDelete } =
    useMultiroleFormAdaptor({
      form,
      onDelete: async () => {
        const result = await removeFn();
        if (!result.success) {
          setError("description", { message: result.error });
        }
      },
    });

  return (
    <Form.Form title="SSH public key" {...formProps}>
      <Form.Field {...register("id")} readOnly />
      <Form.Field {...register("description")} readOnly />
      <Form.TextAreaField {...register("publicKey")} readOnly />
      <Form.Field {...register("fingerprint")} readOnly />
      <Form.Field {...registerConfirmDelete()} type="checkbox" />
    </Form.Form>
  );
};

export default Edit;
