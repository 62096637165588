
type Rename<T, K extends keyof T, N extends string> = Pick<T, Exclude<keyof T, K>> & { [P in N]: T[K] }

const useFlip = <T extends {nodes: unknown}>({nodes, ...props}: T): Rename<T, "nodes", "items"> => {
  return {
  items: nodes,
  ...props,
  };
};


export default useFlip;
