import { gql } from "__generated__";
import useItem_ from "new/model/useItem";

import useRemove from "./useRemove";

const query = gql(`
  query domain($id: ID!) {
    domain(id: $id) {
      id
      name
      dns {
        txt {
          active
          target
        }
        alias {
          active
          target
        }
      }
    }
  }
`);

const useItem = (organisationId: string, id: string) => {
  const item = useItem_(
    {
      query,
      keyFn: (data) => ["organisation", organisationId, "domain", data.id],
      resultFn: (data) => data.domain,
    },
    { id }
  );
  const { remove } = useRemove(organisationId);
  return {
    ...item,
    remove: () => remove({ id }),
  };
};

export default useItem;
