import { useMultiroleFormAdaptor } from "Component";
import { Alert, Form } from "Component";
import { useForm } from "react-hook-form";

import type { ReturnT as DataReturnT } from "../../../DataCallback";

const Create = ({
  createFn,
}: {
  createFn: ({
    email,
    role,
  }: {
    email: string;
    role: "admin" | "member";
  }) => DataReturnT<unknown>;
}) => {
  const form = useForm<{
    email: string;
    role: "admin" | "member";
    deleteConfirmed?: boolean;
    submitConfirmed?: boolean;
  }>({
    defaultValues: {
      email: "",
      role: "admin",
      deleteConfirmed: false,
      submitConfirmed: true,
    },
  });
  const {
    setError,
    formState: { errors },
  } = form;

  const { formProps, register } = useMultiroleFormAdaptor({
    form,
    onSubmit: async ({ email, role }) => {
      const result = await createFn({ email, role });
      if (!result.success) {
        setError("root.serverError", { message: result.error });
      }
    },
  });

  return (
    <Form.Form title="create member" {...formProps}>
      <Form.Field
        {...register("email", {
          required: "an email is required",
        })}
        type="email"
        autoFocus
      />

      <Form.Field
        {...register("role", {
          required: true,
        })}
        hidden
        readOnly
      />

      {errors.root?.serverError && errors.root?.serverError.message && (
        <Alert headline={errors.root?.serverError.message} />
      )}
    </Form.Form>
  );
};
export default Create;
