import { gql } from "__generated__";
import useMutation, { VariablesOf } from "new/model/useMutation";

const query = gql(`
  mutation deleteOrganisation($input: DeleteOrganisationInput!) {
    deleteOrganisation(input: $input) {
      organisationId
    }
  }
`);

const useRemove = () => {
  const { mutate, ...rest } = useMutation({
    query,
    resultFn: (data) => data.deleteOrganisation,
    keyFn: (data) => ["organisation"],
  });
  return {
    remove: (props: VariablesOf<typeof query>["input"]) =>
      mutate({ input: { ...props } }),
  };
};
export default useRemove;
