import { Alert, Container } from "Component";
import { Link } from "Component/router";

const DnsAlert = ({
  type,
  active = false,
  target = undefined,
}: {
  type: "txt" | "alias";
  active?: boolean;
  target?: string;
}) => {
  if (active) {
    return null;
  }
  const hint =
    type == "txt"
      ? `create a DNS TXT type record with a value of ${target}`
      : `please create a CNAME record with a value of ${target} or an A record`;
  return (
    <Alert
      headline={`there is an issue with your ${type.toUpperCase()} DNS record`}
      details={[
        hint,
        <>
          check{" "}
          <Link
            to="http://localhost.run/docs/custom-domains/#setup-dns-for-your-own-domain"
            target="_lhrdocs"
          >
            the DNS documentation
          </Link>{" "}
          for more details
        </>,
      ].map((d, idx) => (
        <Container row spaced key={idx}>
          {d}
        </Container>
      ))}
    />
  );
};

export default DnsAlert;
