import classNames from "classnames";
import { forwardRef } from "react";
import type { ComponentProps, ComponentPropsWithRef } from "react";

import style from "./TextArea.module.css";

const TextArea = forwardRef(function TextArea(
  {
    className,
    ...props
  }: Pick<
    ComponentProps<"textarea">,
    | "id"
    | "placeholder"
    | "required"
    | "disabled"
    | "autoComplete"
    | "hidden"
    | "readOnly"
    | "value"
    | "autoFocus"
    | "className"
    | "maxLength"
    | "name"
    | "rows"
  >,
  ref: ComponentPropsWithRef<"textarea">["ref"]
) {
  return (
    <textarea
      ref={ref}
      className={classNames(style.textarea, className)}
      {...props}
    />
  );
});

export default TextArea;
