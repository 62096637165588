/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

import * as types from "./graphql";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  query organisationBilling($id: ID!, $interval: BillingIntervalEnum = year, $returnUrl: AWSURL) {\n    organisation(id: $id) {\n      id\n      name\n      billing {\n        active\n        needsAttention\n        checkoutUrl(interval: $interval, returnUrl: $returnUrl)\n        billingPortalSessionUrl(returnUrl: $returnUrl)\n      }\n    }\n  }\n":
    types.OrganisationBillingDocument,
  "\n  query organisations($after: String, $first: Int!) {\n    user {\n      id\n      email\n      organisations(after: $after, first: $first) {\n        edges {\n          node {\n            id\n            name\n            billing {\n              active\n              needsAttention\n            }\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n":
    types.OrganisationsDocument,
  "\n  mutation createOrganisation($input: CreateOrganisationInput!) {\n    createOrganisation(input: $input) {\n      organisation {\n        id\n        name\n      }\n    }\n  }\n":
    types.CreateOrganisationDocument,
  "\n  query organisation($id: ID!) {\n    organisation(id: $id) {\n      id\n      name\n      billing {\n        active\n        needsAttention\n      \n      }\n    }\n  }\n":
    types.OrganisationDocument,
  "\n  mutation deleteOrganisation($input: DeleteOrganisationInput!) {\n    deleteOrganisation(input: $input) {\n      organisationId\n    }\n  }\n":
    types.DeleteOrganisationDocument,
  "\n  mutation updateOrganisation($input: UpdateOrganisationInput!) {\n    updateOrganisation(input: $input) {\n      organisation {\n        id\n        name\n      }\n    }\n  }\n":
    types.UpdateOrganisationDocument,
  "\n  query domains($organisationId: ID!, $after: String) {\n    organisation(id: $organisationId) {\n      id\n      domains(after: $after, first: 20) {\n        edges {\n          node {\n            id\n            name\n            dns {\n              txt {\n                active\n                target\n              }\n              alias {\n                active\n                target\n              }\n            }\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n":
    types.DomainsDocument,
  "\n  mutation createDomain($input: CreateDomainInput!) {\n    createDomain(input: $input) {\n      domain {\n        id\n        name\n        dns {\n          txt {\n            active\n            target\n          }\n          alias {\n            active\n            target\n          }\n        }\n      }\n    }\n  }\n":
    types.CreateDomainDocument,
  "\n  query domain($id: ID!) {\n    domain(id: $id) {\n      id\n      name\n      dns {\n        txt {\n          active\n          target\n        }\n        alias {\n          active\n          target\n        }\n      }\n    }\n  }\n":
    types.DomainDocument,
  "\n  mutation deleteDomain($input: DeleteDomainInput!) {\n    deleteDomain(input: $input) {\n      domainId\n    }\n  }\n":
    types.DeleteDomainDocument,
  "\n  query members($organisationId: ID!, $after: String) {\n    organisation(id: $organisationId) {\n      id\n      members(after: $after, first: 20) {\n        nodes {\n          email\n          role\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n":
    types.MembersDocument,
  "\n  mutation createOrganisationMember($input: CreateOrganisationMemberInput!) {\n    createOrganisationMember(input: $input) {\n      member {\n        organisationId\n        email\n        role\n      }\n    }\n  }\n":
    types.CreateOrganisationMemberDocument,
  "\n  query organisationMember($organisationId: ID!, $email: AWSEmail!) {\n    organisationMember(organisationId: $organisationId, email: $email) {\n      email\n      role\n    }\n  }\n":
    types.OrganisationMemberDocument,
  "\n  mutation deleteOrganisationMember($input: DeleteOrganisationMemberInput!) {\n    deleteOrganisationMember(input: $input) {\n      organisationId\n      email\n    }\n  }\n":
    types.DeleteOrganisationMemberDocument,
  "\n  query organisations2($after: String) {\n    user {\n      id\n      email\n      organisations(after: $after, first: 20) {\n        edges {\n          node {\n            id\n            name\n            billing {\n              active\n              needsAttention\n            }\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n":
    types.Organisations2Document,
  "\n  query sshKeyPairs($after: String) {\n    user {\n      id\n      email\n      sshKeyPairs(after: $after, first: 20) {\n        edges {\n          node {\n            id\n            description\n            publicKey\n            fingerprint\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n":
    types.SshKeyPairsDocument,
  "\n  mutation createSshKeyPair($input: CreateSshKeyPairInput!) {\n    createSshKeyPair(input: $input) {\n      sshKeyPair {\n        id\n      }\n    }\n  }\n":
    types.CreateSshKeyPairDocument,
  "\n  query sshKeyPair($id: ID!) {\n    sshKeyPair(id: $id) {\n      id\n      description\n      publicKey\n      fingerprint\n    }\n  }\n":
    types.SshKeyPairDocument,
  "\n  mutation deleteSshKeyPair($input: DeleteSshKeyPairInput!) {\n    deleteSshKeyPair(input: $input) {\n      sshKeyPairId\n    }\n  }\n":
    types.DeleteSshKeyPairDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query organisationBilling($id: ID!, $interval: BillingIntervalEnum = year, $returnUrl: AWSURL) {\n    organisation(id: $id) {\n      id\n      name\n      billing {\n        active\n        needsAttention\n        checkoutUrl(interval: $interval, returnUrl: $returnUrl)\n        billingPortalSessionUrl(returnUrl: $returnUrl)\n      }\n    }\n  }\n"
): (typeof documents)["\n  query organisationBilling($id: ID!, $interval: BillingIntervalEnum = year, $returnUrl: AWSURL) {\n    organisation(id: $id) {\n      id\n      name\n      billing {\n        active\n        needsAttention\n        checkoutUrl(interval: $interval, returnUrl: $returnUrl)\n        billingPortalSessionUrl(returnUrl: $returnUrl)\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query organisations($after: String, $first: Int!) {\n    user {\n      id\n      email\n      organisations(after: $after, first: $first) {\n        edges {\n          node {\n            id\n            name\n            billing {\n              active\n              needsAttention\n            }\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"
): (typeof documents)["\n  query organisations($after: String, $first: Int!) {\n    user {\n      id\n      email\n      organisations(after: $after, first: $first) {\n        edges {\n          node {\n            id\n            name\n            billing {\n              active\n              needsAttention\n            }\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation createOrganisation($input: CreateOrganisationInput!) {\n    createOrganisation(input: $input) {\n      organisation {\n        id\n        name\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation createOrganisation($input: CreateOrganisationInput!) {\n    createOrganisation(input: $input) {\n      organisation {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query organisation($id: ID!) {\n    organisation(id: $id) {\n      id\n      name\n      billing {\n        active\n        needsAttention\n      \n      }\n    }\n  }\n"
): (typeof documents)["\n  query organisation($id: ID!) {\n    organisation(id: $id) {\n      id\n      name\n      billing {\n        active\n        needsAttention\n      \n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation deleteOrganisation($input: DeleteOrganisationInput!) {\n    deleteOrganisation(input: $input) {\n      organisationId\n    }\n  }\n"
): (typeof documents)["\n  mutation deleteOrganisation($input: DeleteOrganisationInput!) {\n    deleteOrganisation(input: $input) {\n      organisationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation updateOrganisation($input: UpdateOrganisationInput!) {\n    updateOrganisation(input: $input) {\n      organisation {\n        id\n        name\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation updateOrganisation($input: UpdateOrganisationInput!) {\n    updateOrganisation(input: $input) {\n      organisation {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query domains($organisationId: ID!, $after: String) {\n    organisation(id: $organisationId) {\n      id\n      domains(after: $after, first: 20) {\n        edges {\n          node {\n            id\n            name\n            dns {\n              txt {\n                active\n                target\n              }\n              alias {\n                active\n                target\n              }\n            }\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"
): (typeof documents)["\n  query domains($organisationId: ID!, $after: String) {\n    organisation(id: $organisationId) {\n      id\n      domains(after: $after, first: 20) {\n        edges {\n          node {\n            id\n            name\n            dns {\n              txt {\n                active\n                target\n              }\n              alias {\n                active\n                target\n              }\n            }\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation createDomain($input: CreateDomainInput!) {\n    createDomain(input: $input) {\n      domain {\n        id\n        name\n        dns {\n          txt {\n            active\n            target\n          }\n          alias {\n            active\n            target\n          }\n        }\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation createDomain($input: CreateDomainInput!) {\n    createDomain(input: $input) {\n      domain {\n        id\n        name\n        dns {\n          txt {\n            active\n            target\n          }\n          alias {\n            active\n            target\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query domain($id: ID!) {\n    domain(id: $id) {\n      id\n      name\n      dns {\n        txt {\n          active\n          target\n        }\n        alias {\n          active\n          target\n        }\n      }\n    }\n  }\n"
): (typeof documents)["\n  query domain($id: ID!) {\n    domain(id: $id) {\n      id\n      name\n      dns {\n        txt {\n          active\n          target\n        }\n        alias {\n          active\n          target\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation deleteDomain($input: DeleteDomainInput!) {\n    deleteDomain(input: $input) {\n      domainId\n    }\n  }\n"
): (typeof documents)["\n  mutation deleteDomain($input: DeleteDomainInput!) {\n    deleteDomain(input: $input) {\n      domainId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query members($organisationId: ID!, $after: String) {\n    organisation(id: $organisationId) {\n      id\n      members(after: $after, first: 20) {\n        nodes {\n          email\n          role\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"
): (typeof documents)["\n  query members($organisationId: ID!, $after: String) {\n    organisation(id: $organisationId) {\n      id\n      members(after: $after, first: 20) {\n        nodes {\n          email\n          role\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation createOrganisationMember($input: CreateOrganisationMemberInput!) {\n    createOrganisationMember(input: $input) {\n      member {\n        organisationId\n        email\n        role\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation createOrganisationMember($input: CreateOrganisationMemberInput!) {\n    createOrganisationMember(input: $input) {\n      member {\n        organisationId\n        email\n        role\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query organisationMember($organisationId: ID!, $email: AWSEmail!) {\n    organisationMember(organisationId: $organisationId, email: $email) {\n      email\n      role\n    }\n  }\n"
): (typeof documents)["\n  query organisationMember($organisationId: ID!, $email: AWSEmail!) {\n    organisationMember(organisationId: $organisationId, email: $email) {\n      email\n      role\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation deleteOrganisationMember($input: DeleteOrganisationMemberInput!) {\n    deleteOrganisationMember(input: $input) {\n      organisationId\n      email\n    }\n  }\n"
): (typeof documents)["\n  mutation deleteOrganisationMember($input: DeleteOrganisationMemberInput!) {\n    deleteOrganisationMember(input: $input) {\n      organisationId\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query organisations2($after: String) {\n    user {\n      id\n      email\n      organisations(after: $after, first: 20) {\n        edges {\n          node {\n            id\n            name\n            billing {\n              active\n              needsAttention\n            }\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"
): (typeof documents)["\n  query organisations2($after: String) {\n    user {\n      id\n      email\n      organisations(after: $after, first: 20) {\n        edges {\n          node {\n            id\n            name\n            billing {\n              active\n              needsAttention\n            }\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query sshKeyPairs($after: String) {\n    user {\n      id\n      email\n      sshKeyPairs(after: $after, first: 20) {\n        edges {\n          node {\n            id\n            description\n            publicKey\n            fingerprint\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"
): (typeof documents)["\n  query sshKeyPairs($after: String) {\n    user {\n      id\n      email\n      sshKeyPairs(after: $after, first: 20) {\n        edges {\n          node {\n            id\n            description\n            publicKey\n            fingerprint\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation createSshKeyPair($input: CreateSshKeyPairInput!) {\n    createSshKeyPair(input: $input) {\n      sshKeyPair {\n        id\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation createSshKeyPair($input: CreateSshKeyPairInput!) {\n    createSshKeyPair(input: $input) {\n      sshKeyPair {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query sshKeyPair($id: ID!) {\n    sshKeyPair(id: $id) {\n      id\n      description\n      publicKey\n      fingerprint\n    }\n  }\n"
): (typeof documents)["\n  query sshKeyPair($id: ID!) {\n    sshKeyPair(id: $id) {\n      id\n      description\n      publicKey\n      fingerprint\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation deleteSshKeyPair($input: DeleteSshKeyPairInput!) {\n    deleteSshKeyPair(input: $input) {\n      sshKeyPairId\n    }\n  }\n"
): (typeof documents)["\n  mutation deleteSshKeyPair($input: DeleteSshKeyPairInput!) {\n    deleteSshKeyPair(input: $input) {\n      sshKeyPairId\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
