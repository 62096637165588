import { Button, Container } from "Component";
import { Link, Route } from "Component/router";
import type { ReactNode } from "react";

import Routes from "./Routes";

const Intersection = ({
  collection,
  item,
  create,
}: {
  collection: ReactNode;
  item: ReactNode;
  create?: ReactNode;
}) => {
  return (
    <Routes>
      <Route
        index
        element={
          <>
            {collection}
            {create && (
              <Container row>
                <Button<typeof Link> as={Link} to="create">
                  new
                </Button>
              </Container>
            )}
          </>
        }
      />
      {create && <Route path="create" element={create} />}
      <Route path=":id/*" element={item} />
    </Routes>
  );
};

export default Intersection;
