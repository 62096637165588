import { gql } from "__generated__";

import useCollection_ from "../useCollection";

const query = gql(`
  query sshKeyPairs($after: String) {
    user {
      id
      email
      sshKeyPairs(after: $after, first: 20) {
        edges {
          node {
            id
            description
            publicKey
            fingerprint
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`);

const useCollection = () => {
  const result = useCollection_({
    query,
    keyFn: () => ["sshkey"],
    resultFn: (data) => {
      const { pageInfo, edges } = data.user.sshKeyPairs;
      return {
        pageInfo,
        nodes: edges.map(({ node }) => node),
      };
    },
  });
  return result;
};

export default useCollection;
