import React from "react";
import { Navigate as _Navigate, useLocation } from "react-router-dom";

const Navigate = ({
  to, // eslint-disable-line react/prop-types
  ...props
}: {
  // to: string;
  // } & Pick<React.ComponentProps<typeof _Navigate>, "replace">) => {
} & React.ComponentProps<typeof _Navigate>) => {
  const { search } = useLocation();
  return <_Navigate to={to + search} {...props} />;
};

export default Navigate;
