import React from "react";

import Container from "../Container";
import style from "./Item.module.css";

const Item = ({ id, children }: { id: string; children: React.ReactNode }) => (
  <Container row spaced>
    <Container className={style.idContainer}>
      <span className={style.id}>{id}</span>
    </Container>
    <Container spaced row className={style.detailsContainer}>
      {children}
    </Container>
  </Container>
);

export default Item;
