import { Form } from "Component";
import { useMultiroleFormAdaptor } from "Component";
import { Alert } from "Component";
import { useForm } from "react-hook-form";

import type { ReturnT as DataReturnT } from "../../DataCallback";

const Edit = ({
  item,
  updateFn,
  removeFn,
}: {
  item: {
    id: string;
    name: string;
    billing: {
      active: boolean;
      needsAttention: boolean;
    };
  };
  updateFn: ({ name }: { name: string }) => DataReturnT<undefined>;
  removeFn: () => DataReturnT<undefined>;
}) => {
  const form = useForm<{
    name: string;
    id: string;
    deleteConfirmed?: boolean;
  }>({
    values: {
      name: item.name,
      id: item.id,
      deleteConfirmed: false,
    },
  });

  const {
    setError,
    formState: { errors },
  } = form;

  const { formProps, register, registerConfirmDelete } =
    useMultiroleFormAdaptor({
      form,
      onDelete: async () => {
        const result = await removeFn();
        if (!result.success) {
          setError("root.serverError", { message: result.error });
        }
      },
      onSubmit: async ({ name }) => {
        const result = await updateFn({ name });
        if (!result.success) {
          setError("root.serverError", { message: result.error });
        }
      },
    });
  return (
    <Form.Form title="organisation" {...formProps}>
      {item.billing.needsAttention && (
        <Alert
          headline="there is an error with your billing"
          details="visit the billing tab"
        />
      )}
      <Form.Field {...register("id")} readOnly />
      <Form.Field {...register("name")} />
      <Form.Field {...registerConfirmDelete()} type="checkbox" />
      {errors.root?.serverError && errors.root?.serverError.message && (
        <Alert headline={errors.root?.serverError.message} />
      )}
    </Form.Form>
  );
};

export default Edit;
