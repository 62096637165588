import { gql } from "__generated__";

import useItem_ from "../useItem";
import useRemove from "./useRemove";

const query = gql(`
  query sshKeyPair($id: ID!) {
    sshKeyPair(id: $id) {
      id
      description
      publicKey
      fingerprint
    }
  }
`);

const useItem = (id: string) => {
  const item = useItem_(
    {
      query,
      keyFn: (data) => ["sshkey", data.id],
      resultFn: (data) => data.sshKeyPair,
    },
    { id }
  );
  const { mutate } = useRemove();
  return {
    ...item,
    remove: () => mutate({ id }),
  };
};

export default useItem;
