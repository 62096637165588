import { gql } from "__generated__";
import useItem_ from "new/model/useItem";

import useRemove from "./useRemove";
import useUpdate from "./useUpdate";

const query = gql(`
  query organisation($id: ID!) {
    organisation(id: $id) {
      id
      name
      billing {
        active
        needsAttention
      
      }
    }
  }
`);

const useItem = (id: string) => {
  const item = useItem_(
    {
      query,
      keyFn: (data) => ["organisation", data.id],
      resultFn: (data) => data.organisation,
    },
    { id }
  );
  const { remove } = useRemove();
  const { update } = useUpdate();
  return {
    ...item,
    remove: () => remove({ id }),
    update: (props: Omit<Parameters<typeof update>[0], "id">) =>
      update({ id, ...props }),
  };
};

export default useItem;

// import { GraphQLQuery } from "@aws-amplify/api";
// import { useQuery } from "@tanstack/react-query";
// import { gql } from "__generated__";
// import type {
//   OrganisationQuery,
//   OrganisationQueryVariables,
// } from "__generated__/graphql";
// import { API, graphqlOperation } from "aws-amplify";

// import { parseR } from "../utils";
// import useRemove from "./useRemove";
// import useUpdate from "./useUpdate";

// const query = gql(`
//   query organisation($id: ID!) {
//     organisation(id: $id) {
//       id
//       name
//       billing {
//         active
//         needsAttention

//       }
//     }
//   }
// `);

// const useItem = (id: string) => {
//   const apiFn = (props: OrganisationQueryVariables) =>
//     API.graphql<GraphQLQuery<OrganisationQuery>>(graphqlOperation(query, props))
//       .then(parseR)
//       .then((data) => data.organisation);

//   const { data } = useQuery(["organisations", id], () => apiFn({ id }));

//   const { remove } = useRemove(id);
//   const { update } = useUpdate(id);

//   return {
//     data,
//     remove,
//     update,
//   };
// };
