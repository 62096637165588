import { gql } from "__generated__";
import useMutation, { VariablesOf } from "new/model/useMutation";

const query = gql(`
  mutation createOrganisationMember($input: CreateOrganisationMemberInput!) {
    createOrganisationMember(input: $input) {
      member {
        organisationId
        email
        role
      }
    }
  }
`);

const useCreate = (organisationId: string) => {
  const { mutate, ...rest } = useMutation({
    query,
    resultFn: (data) => data.createOrganisationMember.member,
    keyFn: () => ["organisation", organisationId, "member"],
  });
  return {
    mutate: (
      props: Omit<VariablesOf<typeof query>["input"], "organisationId">
    ) => mutate({ input: { organisationId, ...props } }),
    ...rest,
  };
};

export default useCreate;
