import { useQuery } from "@tanstack/react-query";
import React, { createContext, useContext } from "react";

interface Ctx {
  Amplify: object;
  stripe: {
    publishable_key: string;
  };
  sentry: {
    dsn: string;
    environment: string;
  };
  lhr: {
    vote_url?: string;
  };
}

const ConfigContext = createContext<Ctx>({
  Amplify: {},
  stripe: { publishable_key: "" },
  sentry: { dsn: "", environment: "local" },
  lhr: {
    vote_url: undefined,
  },
});

export const _useConfig = () => useContext(ConfigContext);

const fetchConfig = () =>
  fetch("/config.json", { cache: "no-cache" }).then((response) =>
    response.json()
  );

const Config = ({ children }: { children?: React.ReactNode }) => {
  const query = useQuery<Ctx>({ queryKey: ["config"], queryFn: fetchConfig });

  const config = query.data;
  if (!config) {
    throw new Error("bad config :(");
  }
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export { ConfigContext };
export default Config;
