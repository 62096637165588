import { gql } from "__generated__";
import useMutation, { VariablesOf } from "new/model/useMutation";

const query = gql(`
  mutation deleteDomain($input: DeleteDomainInput!) {
    deleteDomain(input: $input) {
      domainId
    }
  }
`);

const useRemove = (organisationId: string) => {
  const { mutate, ...rest } = useMutation({
    query,
    resultFn: (data) => data.deleteDomain,
    keyFn: (data) => ["organisation", organisationId, "domain"],
  });
  return {
    remove: (props: VariablesOf<typeof query>["input"]) =>
      mutate({ input: { ...props } }),
  };
};
export default useRemove;
