import _Button from "Component/Button";
import Container from "Component/Container";
import type { ComponentProps } from "react";

import style from "./Form.module.css";

const Button = (
  props: Pick<
    ComponentProps<typeof _Button<"input">>,
    "value" | "type" | "priority" | "hidden" | "disabled"
  >
) => <_Button<"input"> as="input" style={{ flexGrow: 1 }} {...props} />;

const Form = ({
  title,
  children,
  submitButtonProps,
  resetButtonProps,
  onSubmit = (e) => {
    e.preventDefault();
    console.log("form submitted but not onSubmit was provided");
  },
  ...props
}: {
  title?: string;
  submitButtonProps?: ComponentProps<typeof Button>;
  resetButtonProps?: ComponentProps<typeof Button>;
} & Pick<ComponentProps<"form">, "onSubmit" | "onReset" | "children">) => {
  return (
    <Container<"form"> as={"form"} onSubmit={onSubmit} {...props}>
      <Container<"fieldset"> as="fieldset" border spaced>
        {title && <legend className={style.legend}>{title}</legend>}
        {children}

        <Container row spaced className={style.buttonGroup}>
          <Button
            value="submit"
            priority="primary"
            {...submitButtonProps}
            type="submit"
          />
          <Button
            value="reset"
            priority="warning"
            {...resetButtonProps}
            type="reset"
          />
        </Container>
      </Container>
    </Container>
  );
};

export default Form;
