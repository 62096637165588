import { useCallback } from "react";
import type { FormEvent } from "react";
import type {
  DeepPartial,
  FieldValues,
  Path,
  RegisterOptions,
  SubmitHandler,
  UseFormReturn,
} from "react-hook-form";

const useMultiroleFormAdaptor = <T extends FieldValues>({
  form: {
    formState: {
      isDirty,
      dirtyFields,
      isSubmitting,
      defaultValues,
      errors: _errorsSubscriptionRegistration, // eslint-disable-line @typescript-eslint/no-unused-vars
    },
    handleSubmit,
    reset,
    register,
    getFieldState,
  },
  onDelete,
  onSubmit,
  onReset,
}: {
  form: {
    formState: UseFormReturn<T>["formState"];
  } & Pick<
    UseFormReturn<T>,
    "handleSubmit" | "reset" | "register" | "getFieldState"
  >;
  onDelete?: SubmitHandler<T>;
  onSubmit?: SubmitHandler<T>;
  onReset?: () => void | Promise<void>;
}) => {
  const mode =
    onDelete && (!isDirty || dirtyFields.deleteConfirmed)
      ? "delete"
      : onSubmit && isDirty
      ? "submit"
      : "display";

  const submitButtonProps = {
    hidden: mode === "display",
    value: mode,
    priority: mode === "delete" ? ("danger" as const) : ("primary" as const),
    disabled: isSubmitting,
  };

  const resetButtonProps = {
    hidden: !isDirty || isSubmitting,
    disabled: isSubmitting,
  };

  const _onSubmit = onSubmit ? onSubmit : () => undefined;
  const handledSubmit = useCallback(handleSubmit(_onSubmit), [
    onSubmit,
    handleSubmit,
  ]);

  const _onDelete = onDelete ? onDelete : () => undefined;
  const handledDelete = useCallback(handleSubmit(_onDelete), [
    onDelete,
    handleSubmit,
  ]);

  const _onReset = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (defaultValues !== undefined) {
        reset(defaultValues as DeepPartial<T>);
      } else {
        reset();
      }
      onReset && onReset();
    },
    [onReset, defaultValues, reset]
  );

  const _register = useCallback(
    (name: Path<T>, options?: RegisterOptions<T>) => {
      const r = register(name, options);
      const error = getFieldState(name).error?.message;
      return {
        error,
        ...r,
      };
    },
    [register]
  );

  const registerConfirm = useCallback(
    (_mode: "delete" | "submit") => {
      const name = `${_mode}Confirmed` as Path<T>;
      const fieldState = getFieldState(name);
      return {
        ...register(name as Path<T>, {
          validate: (value) => mode !== _mode || value || `confirm ${_mode}`,
        }),
        type: "checkbox",
        hidden:
          mode !== _mode ||
          (fieldState.error?.type !== "validate" && !fieldState.isDirty),
        // mode !== _mode || (!isDirty && fieldState.error?.type !== "validate"),
        error: fieldState.error?.message?.toString(),
      };
    },
    [register, mode, isDirty, getFieldState]
  );

  return {
    formProps: {
      submitButtonProps,
      resetButtonProps,
      onSubmit:
        mode === "delete"
          ? handledDelete
          : mode === "submit"
          ? handledSubmit
          : undefined,
      onReset: _onReset,
    },
    register: _register,
    registerConfirmDelete: () => registerConfirm("delete"),
    registerConfirmSubmit: () => registerConfirm("submit"),
  };
};

export default useMultiroleFormAdaptor;
