import { Form } from "Component";
import { Alert, useMultiroleFormAdaptor } from "Component";
import { useForm } from "react-hook-form";

import type { ReturnT as DataReturnT } from "../../../DataCallback";

const Edit = ({
  item,
  removeFn,
}: {
  item: {
    email: string;
    role: "admin" | "member";
  };
  removeFn: () => DataReturnT<undefined>;
}) => {
  const form = useForm<{
    email: string;
    role: "admin" | "member";
  }>({
    values: {
      email: item.email,
      role: item.role,
    },
  });
  const {
    setError,
    formState: { errors },
  } = form;

  const { formProps, register, registerConfirmDelete } =
    useMultiroleFormAdaptor({
      form,
      onDelete: async () => {
        const result = await removeFn();
        if (!result.success) {
          setError("root.serverError", { message: result.error });
        }
      },
    });

  return (
    <Form.Form title="member" {...formProps}>
      <Form.Field {...register("email")} readOnly />
      {/* <Form.Field {...register("role")} readOnly /> */}
      <Form.Field {...registerConfirmDelete()} type="checkbox" />

      {errors.root?.serverError && errors.root?.serverError.message && (
        <Alert headline={errors.root?.serverError.message} />
      )}
    </Form.Form>
  );
};

export default Edit;
