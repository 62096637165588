import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Amplify, Auth as AmplifyAuth, Hub } from "aws-amplify";
import { useConfig } from "hooks";
import React, { createContext, useEffect, useState } from "react";

const AuthContext = createContext<{ username: string | undefined | null }>({
  username: undefined,
});

interface AmplifyConfig {
  API?: object;
}

const authQuery = () =>
  AmplifyAuth.currentAuthenticatedUser()
    .then((data) => data.username)
    .catch(() => null);

const Auth = ({ children }: { children?: React.ReactNode }) => {
  const config = useConfig();
  const amplifyConfig: AmplifyConfig = config.Amplify;

  const queryClient = useQueryClient();

  useEffect(() => {
    Amplify.configure({
      ...amplifyConfig,
      API: {
        ...amplifyConfig?.API,
        graphql_headers: async () => {
          const session = await AmplifyAuth.currentSession();
          return {
            Authorization: session.getIdToken().getJwtToken(),
          };
        },
      },
    });
    AmplifyAuth.currentAuthenticatedUser().then((data) =>
      setUsername(data.username)
    );
  }, [amplifyConfig]);

  useQuery({
    queryKey: ["auth"],
    queryFn: authQuery,
  });
  const [username, setUsername] = useState<string | null>(null);
  useEffect(
    () =>
      Hub.listen("auth", ({ payload: { event, data } }) => {
        switch (event) {
          // case 'cognitoHostedUI':
          case "signIn":
            // console.log(`${Date()} signed in`, data);
            setUsername(data.username);
            break;
          case "signOut":
            // console.log(`${Date()} signed out`);
            setUsername(null);
            queryClient.clear();
            break;
          // case 'cognitoHostedUI_failure':
          case "signIn_failure":
            // console.log(`${Date()} Sign in failure`, data);
            break;
          default:
            // console.log(`${Date()} unhandled hub event`, event, data);
            break;
        }
      }),
    [queryClient]
  );

  return (
    <AuthContext.Provider value={{ username }}>{children}</AuthContext.Provider>
  );
};

export { AuthContext };

export default Auth;
